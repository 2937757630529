import axios from "axios";
import config from "@config/config";
import { getDeviceInfo } from "../../../utility/device_info";
import { StorageManager } from "@lib/utility/storageManager";
import { v1 as uuidV1 } from "uuid";

const getDeviceId = () => {
  const existingDeviceId = localStorage.getItem("@zobaze/device_id");
  if (existingDeviceId) {
    return existingDeviceId;
  }
  const newDeviceId = uuidV1();
  localStorage.setItem("@zobaze/device_id", newDeviceId);
  return newDeviceId;
};

const getSessionId = () => {
  const existingSessionId = sessionStorage.getItem("@zobaze/session_id");
  if (existingSessionId) {
    return existingSessionId;
  }
  const newSessionId = uuidV1();
  sessionStorage.setItem("@zobaze/session_id", newSessionId);
  return newSessionId;
};

const getSupplementaryData = async () => {
  const deviceInfo = getDeviceInfo();
  const userStorage = StorageManager.get("user");
  const storedUser = await userStorage.getItem("current-user");
  const event = {
    localTime: new Date().getTime(),
    device_id: getDeviceId(),
    session_id: getSessionId(),
    version: config.version,
    os_name: deviceInfo.os.name,
    device_brand: deviceInfo.device.vendor || null,
    device_model: deviceInfo.device.model || null,
    device_type: deviceInfo.device.type || null,
    user_id: storedUser ? storedUser.id : null,
  };
  return event;
};

const logNewSession = async (storeId) => {
  if (sessionStorage.getItem("@zobaze/session_id")) {
    return;
  }
  const supplementaryData = await getSupplementaryData();
  const resource = `${config.serverlessUrl}analytics/logEvent`;
  const response = await axios.post(resource, {
    event_type: "new_session",
    value: window.location.href,
    sf_id: storeId,
    ...supplementaryData,
  });
  return response.data;
};

const logPageVisit = async (storeId) => {
  const supplementaryData = await getSupplementaryData();
  const resource = `${config.serverlessUrl}analytics/logEvent`;
  const response = await axios.post(resource, {
    event_type: "page_visit",
    value: window.location.href,
    sf_id: storeId,
    ...supplementaryData,
  });
  return response.data;
};

const logAddToCart = async (storeId, product_id) => {
  const supplementaryData = await getSupplementaryData();
  const resource = `${config.serverUrl}sf/analytics/logEvent`;
  const response = await axios.post(resource, {
    event_type: "add_to_cart",
    product_id,
    sf_id: storeId,
    ...supplementaryData,
  });
  return response.data;
};

const logOrderCreated = async (storeId) => {
  const supplementaryData = await getSupplementaryData();
  const resource = `${config.serverUrl}sf/analytics/logEvent`;
  const response = await axios.post(resource, {
    event_type: "order_placed",
    sf_id: storeId,
    ...supplementaryData,
  });
  return response.data;
};

export {
  logNewSession,
  logPageVisit,
  logAddToCart,
  logOrderCreated,
  getDeviceId,
};
