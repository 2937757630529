import Vue from "vue";
import { CartModule } from "@lib/modules/cart";
import { CatalogModule } from "@lib/modules/catalog";
import { NotificationModule } from "@lib/modules/notification";
import { OrderModule } from "@lib/modules/order";
import { CheckoutModule } from "@lib/modules/checkout";
import { UserModule } from "@lib/modules/user";
import { AppointmentModule } from "@lib/modules/appointment";
import { GuestModule } from "@lib/modules/guest";
import { price as priceFilter } from "@lib/filters/price";
import { date as dateFilter } from "@lib/filters/date";
import { capitalize as capitalizeFilter } from "@lib/filters/capitalize";

import { registerModule, registerApp } from "@lib/appRegister";
import { Store } from "vuex";
import VueRouter from "vue-router";
import RootState from "@lib/types/RootState";

const registerModules = () => {
  registerModule(CartModule);
  registerModule(CatalogModule);
  registerModule(NotificationModule);
  registerModule(OrderModule);
  registerModule(CheckoutModule);
  registerModule(UserModule);
  registerModule(AppointmentModule);
  registerModule(GuestModule);
};

const registerPaymentModules = (store, providers) => {
  if (!providers) return;
  const registeredModulesMap = {};
  for (const provider of providers) {
    if (provider.id && provider.providerName)
      // Add to payment providers
      store.dispatch(
        "order/addPaymentProvider",
        {
          id: provider.id,
          providerName: provider.providerName,
          buttonText: provider.buttonText,
        },
        { root: true }
      );

    // Register dynamic modules based on type
    if (registeredModulesMap[provider.providerName]) continue;
    if (provider.providerName === "payu") {
      import(/* webpackChunkName: "payu" */ "@lib/modules/payment/payu").then(
        ({ PayUModule }) => {
          registerModule(PayUModule);
        }
      );
    } else if (provider.providerName === "upi-manual") {
      import(
        /* webpackChunkName: "upi-manual" */ "@lib/modules/payment/upi-manual"
      ).then(({ UpiManualModule }) => {
        registerModule(UpiManualModule);
      });
    } else if (provider.providerName === "pof") {
      import(/* webpackChunkName: "pof" */ "@lib/modules/payment/pof").then(
        ({ CodModule }) => {
          registerModule(CodModule);
        }
      );
    }
    registeredModulesMap[provider.providerName] = true;
  }
};

const registerFilters = () => {
  Vue.filter("price", priceFilter);
  Vue.filter("date", dateFilter);
  Vue.filter("capitalize", capitalizeFilter);
};

export const register = (
  app: any,
  store: Store<RootState>,
  router: VueRouter
) => {
  registerApp(app, store, router, {});
  registerModules();
  registerFilters();
  const sfConfig = store.state.store;
  if (
    store.getters.paymentsEnabled &&
    sfConfig.payment &&
    sfConfig.payment.providers &&
    sfConfig.payment.providers.length
  ) {
    registerPaymentModules(store, sfConfig.payment.providers);
  }
};
