<template>
  <div class="overlay fixed w-100" @click="close" v-if="isVisible" />
</template>

<script>
export default {
  computed: {
    isVisible() {
      return this.$store.state.ui.overlay;
    },
  },
  methods: {
    close() {
      this.$store.commit("ui/setOverlay", false);
      this.$store.commit("ui/setMicrocart", false);
      this.$store.commit("ui/setWishlist", false);
      this.$store.commit("ui/setSearchpanel", false);
      this.$store.commit("ui/setSidebar", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "~@theme/css/variables/colors" as *;
@use "~@theme/css/helpers/functions/color" as *;
@use "~@theme/css/base/global_vars" as *;
$color-bg: color(black);
$z-index-overlay: map-get($z-index, overlay);

.overlay {
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba($color-bg, 0.4);
  z-index: $z-index-overlay;
}
</style>
