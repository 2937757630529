<template>
  <div class="header">
    <header
      class="fixed w-100 brdr-bottom-1 bg-cl-primary brdr-cl-secondary"
      :class="{ 'is-visible': navVisible }"
    >
      <div class="container p0">
        <div class="row header__row">
          <div class="col-md-2 col-xs-2">
            <SfButton
              class="sf-button--pure"
              style="outline: none"
              @click="goBack"
            >
              <SfIcon
                icon="chevron_left"
                size="18px"
                role="button"
                aria-label="back"
              />
            </SfButton>
          </div>
          <div class="col-md-8 col-xs-10">
            <span class="store-title">{{ storeTitle }}</span>
          </div>
        </div>
      </div>
    </header>
    <div class="header-placeholder" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import { SfIcon, SfButton } from "@lib/components";

export default {
  name: "BasicHeader",
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    storeTitle: String,
  },
  data() {
    return {
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 54,
    };
  },
  computed: {
    ...mapState({
      isOpenLogin: (state) => state.ui.signUp,
      currentUser: (state) => state.user.current,
    }),
    isThankYouPage() {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false;
    },
  },
  beforeMount() {
    window.addEventListener(
      "scroll",
      () => {
        this.isScrolling = true;
      },
      { passive: true }
    );

    setInterval(() => {
      if (this.isScrolling) {
        this.hasScrolled();
        this.isScrolling = false;
      }
    }, 250);
  },
  methods: {
    gotoAccount() {
      this.$bus.$emit("modal-toggle", "modal-signup");
    },
    hasScrolled() {
      this.scrollTop = window.scrollY;
      if (
        this.scrollTop > this.lastScrollTop &&
        this.scrollTop > this.navbarHeight
      ) {
        this.navVisible = false;
      } else {
        this.navVisible = true;
      }
      this.lastScrollTop = this.scrollTop;
    },
    goBack() {
      if (window.history.length <= 1) {
        this.$router.push({ name: "catalog" });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "~@theme/css/variables/colors" as *;
@use "~@theme/css/helpers/functions/color" as *;
$color-icon-hover: color(secondary, $colors-background);

.store-title {
  font-size: 1.05rem;
  font-family: Raleway, sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sf-button--pure {
  --icon-width: 1rem;
  --icon-color: white;
  padding: 18px;
}

.header {
  &__row {
    align-items: center;
  }
}

header {
  background: var(--header-background, var(--color-primary));
  color: white;
  height: 54px;
  top: -55px;
  z-index: 3;
  transition: top 0.2s ease-in-out;
  &.is-visible {
    top: 0;
  }

  .row {
  }

  .store-title {
    font-weight: 600;
    display: block;
    padding-left: 0;
  }
}
.icon {
  color: white;
  opacity: 0.9;
  &:hover,
  &:focus {
    // background-color: $color-icon-hover;
    opacity: 1;
  }
}
.right-icons {
  //for edge
  float: right;
}
.header-placeholder {
  height: 54px;
}
.links {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .row.middle-xs {
    margin: 0 -15px;

    &.py5 {
      margin: 0;
    }
  }
  .col-xs-2:first-of-type {
    padding-left: 0;
  }
  .col-xs-2:last-of-type {
    padding-right: 0;
  }
  a,
  span {
    font-size: 12px;
  }
}
</style>
