<template>
  <div
    class="cat-grid-item"
    :style="style"
    v-on="isMobile ? $listeners : {}"
    @click="$emit('click')"
  >
    <div class="cat-grid-item__container">
      <slot name="subtitle" v-bind="{ subtitle }">
        <h2 v-if="subtitle" class="cat-grid-item__subtitle">
          {{ subtitle }}
        </h2>
      </slot>
      <slot name="title" v-bind="{ title }">
        <h1 v-if="title" class="cat-grid-item__title">
          {{ title }}
        </h1>
      </slot>
      <slot name="description" v-bind="{ description }">
        <p v-if="description" class="cat-grid-item__description">
          {{ description }}
        </p>
      </slot>
      <slot name="call-to-action" v-bind="{ buttonText }">
        <SfButton
          v-if="buttonText"
          class="cat-grid-item__call-to-action color-secondary desktop-only"
          v-on="!isMobile ? $listeners : {}"
        >
          {{ buttonText }}
        </SfButton>
      </slot>
    </div>
    <p v-if="title" class="cat-grid-item__label mobile-only">
      {{ title }}
    </p>
  </div>
</template>
<script>
import SfButton from "../atoms/SfButton/SfButton.vue";
import {
  mapMobileObserver,
  unMapMobileObserver,
} from "../../utility/mobile-observer";
export default {
  name: "CategoryGridItem",
  components: {
    SfButton,
  },
  props: {
    /**
     * Banner title
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Banner subtitle (at the top)
     */
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    /** text that will be displayed inside the button. You can replace the button  with "call-to-action" slot */
    buttonText: {
      type: String,
      default: "",
    },
    /** Background color in HEX (eg #FFFFFF) */
    background: {
      type: String,
      default: "",
    },
    /** Background image. Influenced by $banner-background-size, $banner-background-position CSS props. */
    image: {
      type: [String, Object],
      default: "",
    },
  },
  computed: {
    ...mapMobileObserver(),
    style() {
      const image = this.image;
      const background = this.background;
      const styles = {};
      if (image) {
        styles["--_banner-background-image"] = `url(${image})`;
      } else if (background) {
        styles["--_banner-background-image"] = "url()";
        styles["--banner-background-color"] = background;
      }
      return styles;
    },
  },
  beforeDestroy() {
    unMapMobileObserver();
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/helpers" as *;
.cat-grid-item {
  box-sizing: border-box;
  display: var(--banner-display, flex);
  align-items: var(--banner-align-items, flex-start);
  justify-content: var(--banner-justify-content, flex-start);
  flex-direction: column;

  @include for-mobile {
    flex-shrink: 0;
    height: 8.75rem;
    width: 6.25rem;
    margin: 0;
    margin-right: 0.5rem;
  }

  @include for-desktop {
    width: 25%;
    padding: var(--banner-padding, var(--spacer-sm));
  }

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    justify-content: var(--banner-container-justify-content, center);
    align-items: var(--banner-container-align-items, center);
    flex-direction: var(--banner-container-flex-direction, column);
    @include background(
      --banner-background,
      var(--_banner-background-color, transparent),
      var(--_banner-background-image)
    );
    border-radius: 0.375rem;

    @include for-mobile {
      flex-shrink: 0;
      height: 6.25rem;
    }
  }
  &__subtitle {
    margin: var(--banner-subtitle-margin, 0);
    color: var(
      --banner-color,
      var(--banner-subtitle-color, var(--c-dark-variant))
    );
    @include font(
      --banner-subtitle-font,
      var(--font-normal),
      var(--font-2xs),
      1.4,
      var(--font-family-secondary)
    );
    text-transform: var(--banner-subtitle-text-transform, uppercase);
  }
  &__title {
    color: var(--banner-color, var(--banner-title-color, var(--c-text)));
    @include font(
      --banner-title-font,
      var(--font-normal),
      var(--font-base),
      1.2,
      var(--font-family-secondary)
    );

    @include for-mobile {
      font-size: var(--font-sm);
    }

    font-weight: bolder;
    color: rgba(0, 0, 0, 0.2);
    padding: 0.2rem;
    text-align: center;
  }
  &__label {
    position: relative;
    @include font(
      --banner-title-font,
      var(--font-medium),
      var(--font-sm),
      0.8,
      var(--font-family-secondary)
    );
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    margin: 0;
    margin-top: 0.5rem;
    line-height: 1rem;
    overflow: hidden;
    height: 2rem;

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 1rem;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), white 100%);
    }
  }
  &__description {
    display: var(--banner-display-description, none);
    margin: var(
      --banner-description-margin,
      var(--spacer-sm) 0 var(--spacer-base) 0
    );
    color: var(--banner-color, var(--banner-description-color, var(--c-text)));
    @include font(
      --banner-description-font,
      var(--font-light),
      var(--font-base),
      1.6,
      var(--font-family-primary)
    );
  }
  &__call-to-action {
    display: var(--banner-display-call-to-action, block);
    --button-padding: var(--spacer-xs) var(--spacer-sm);
    margin-top: var(--spacer-lg);
  }
  @include for-desktop {
    align-items: var(--banner-align-items, center);
    --banner-background-image: var(
      --_banner-background-desktop-image,
      var(--_banner-background-image)
    );
    --banner-display-description: block;
    --banner-display-call-to-action: block;
    --banner-title-margin: var(--spacer-base) 0 0 0;
    --banner-subtitle-color: var(--c-gray);
    --banner-title-font-size: var(--h1-font-size);
    min-height: var(--banner-height, 19rem);
  }
  &--right {
    @include for-desktop {
      --banner-justify-content: flex-end;
    }
  }
}
</style>
