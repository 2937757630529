<template>
  <hr class="sf-divider" />
</template>
<script>
export default {
  name: "SfDivider",
};
</script>
<style lang="scss">
@use "~@lib/styles/components/atoms/SfDivider.scss" as *;
</style>
