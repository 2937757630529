<template>
  <section class="notif-request">
    <transition name="slide-fade">
      <div v-if="showPrompt" class="top-alert" @click="requestPermission">
        <div v-if="!triggerPoint">
          Allow us to send you notifications by
          <a href="#" @click.prevent="requestPermission">clicking here</a>
        </div>
        <div v-else-if="triggerPoint == 'order-success'">
          Get updates regarding your order by
          <a href="#" @click.prevent="requestPermission"
            >enabling notifications</a
          >
        </div>
        <div v-else>
          Allow us to send you notifications by
          <a href="#" @click.prevent="requestPermission">clicking here</a>
        </div>
        <div>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="showPrompt = false"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </transition>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import notificationsService from "@lib/services/notifications";

export default {
  props: {
    forceShow: {
      type: Boolean,
      default: false,
    },
    triggerPoint: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showPrompt: false,
    };
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "userId"]),
    ...mapGetters("push", ["isEnabled"]),
    ...mapGetters(["store"]),
    canShowPrompt() {
      return (
        Notification.permission === "default" &&
        (this.forceShow || this.isLoggedIn)
      );
    },
  },
  watch: {
    canShowPrompt: {
      immediate: true,
      handler: function (val) {
        const self = this;
        if (val) {
          setTimeout(() => {
            self.showPrompt = true;
            setTimeout(() => {
              self.showPrompt = false;
            }, 15000);
          }, 3000);
        }
      },
    },
  },
  methods: {
    async requestPermission() {
      this.showPrompt = false;
      await notificationsService.requestPermission(this.userId);
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/helpers" as *;

.notif-request .top-alert {
  display: flex;
  justify-content: space-between;
  font-size: 0.77rem;
  padding: 0.5rem 1rem;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;

  a {
    color: #004085;
    font-weight: bolder;
    text-decoration: underline;
  }

  button {
    background-color: transparent;
    border: 0;
    padding-right: 0;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}
</style>
