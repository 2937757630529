import CartItem from "@lib/modules/cart/types/CartItem";

const productsEquals = (product1: CartItem, product2: CartItem): boolean => {
  if (!product1 || !product2) {
    return false;
  }

  return product1.id === product2.id;
};

export default productsEquals;
