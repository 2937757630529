import axios from "axios";
import config from "@config/config";

const getOrderFromNotificationToken = async (orderId, token) => {
  const orderResource = config.serverUrl + "api/guest/order/" + orderId;
  const axiosConfig = {
    headers: { authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(orderResource, axiosConfig);
    return { success: true, order: response.data };
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errorMessage
    ) {
      return { success: false, errorMessage: error.response.data.errorMessage };
    }
    return {
      success: false,
      errorMessage:
        "A network error occurred while fetching your order details",
    };
  }
};

export default {
  getOrderFromNotificationToken,
};
