<template>
  <SfButton class="sf-button--pure account-icon-button" @click="goToAccount">
    <SfIcon
      icon="account"
      size="xs"
      class="account-icon"
      :class="{
        'is-active': isLoggedIn,
      }"
    />
  </SfButton>
</template>

<script>
import { SfButton, SfIcon } from "@lib/components";
import { notifications } from "@lib/modules/cart/helpers";

import { mapGetters, mapActions } from "vuex";
import { ModalList } from "@theme/store/ui/modals";
import config from "@config/config";
import shortUid from "../../../lib/utility/shortUid";

function isAndroidMobileOs() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return false; // /windows phone/i.test(userAgent) || /android/i.test(userAgent);
}

export default {
  components: { SfIcon, SfButton },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    ...mapGetters(["store"]),
  },
  methods: {
    ...mapActions("ui", {
      openModal: "openModal",
    }),
    ...mapActions({
      loginUsingCustomToken: "user/loginUsingCustomToken",
    }),
    goToAccount() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "account", params: { activePage: "" } });
      } else {
        if (isAndroidMobileOs()) {
          const truecallerNonce = shortUid();
          const title = this.store.title;
          window.location = [
            "truecallersdk://truesdk/web_verify",
            "?type=btmsheet",
            `&requestNonce="${truecallerNonce}"`,
            "&partnerKey=D4ptOd09507ac8282427da4ca97c21686d52e",
            `&partnerName="Zobaze Staging 2"`,
            '&lang="en-US"',
            "&skipOption=useanothermethod",
          ].join("");

          this.$store.commit("ui/setLoader", true);

          this.$socket.client.emit("set_truecaller_nonce", {
            nonce: truecallerNonce,
            storeId: this.store.id,
          });

          const self = this;
          setTimeout(() => {
            if (document.hasFocus()) {
              this.$store.commit("ui/setLoader", false);
              self.openModal({ name: ModalList.Auth, payload: "login" });
            } else {
              // Good chance for truecaller to have received the intent
              // Remove loading screen after 30 seconds in case no response comes in
              setTimeout(() => {
                this.$store.commit("ui/setLoader", false);
                if (!self.isLoggedIn) {
                  self.$store.dispatch(
                    "notification/spawnNotification",
                    notifications.createNotification({
                      type: "danger",
                      message: "Timed out waiting for verification.",
                    })
                  );
                }
              }, 30 * 1000);
            }
          }, 600);
        } else {
          this.openModal({ name: ModalList.Auth, payload: "login" });
        }
      }
    },
  },
  sockets: {
    connect() {
      console.log("reconnected! Fetching token..");
    },
    reconnect() {
      console.log("reconnecting..");
    },
    disconnect() {
      console.log("disconnected.");
    },
    truecaller_verified(data) {
      console.log("Received verification from server!");
      this.$store.commit("ui/setLoader", false);
      this.loginUsingCustomToken(data.authToken);
    },
    truecaller_verification_error(data) {
      this.$store.commit("ui/setLoader", false);
      this.$store.dispatch(
        "notification/spawnNotification",
        notifications.createNotification({
          type: "danger",
          message: data.message,
        })
      );
      if (data.backoffToOtherLogin) {
        this.openModal({ name: ModalList.Auth, payload: "login" });
      }
    },
    truecaller_nonce_received() {
      console.log("Nonce ack");
    },
  },
};
</script>
