import Vue from "vue";
import Vuex from "vuex";
import RootState from "@lib/types/RootState";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

const state = {
  version: "",
  libInitialized: false,
  storeNotSpecified: false,
  storeFetchWaiting: true,
  storeNotFound: false,
  storeFetchError: null,
  countryCode: "IN",
  storeId: "",
  store: null,
  ui: {},
  config: {},
  cart: {},
  checkout: {},
  cms: {},
  compare: {},
  product: {},
  shipping: {},
  user: {},
  newsletter: {},
  wishlist: {},
  attribute: {
    list_by_code: {},
    list_by_id: {},
    blacklist: [],
    labels: {},
  },
  category: {
    current_path: "",
    current_product_query: {},
    current: {
      slug: "",
      name: "",
    },
    filters: {},
  },
  stock: {
    cache: [],
  },
  storeView: {},
  twoStageCachingDelta1: 0,
  twoStageCachingDelta2: 0,
  twoStageCachingDisabled: false,
  userTokenInvalidated: null,
  userTokenInvalidateAttemptsCount: 0,
  userTokenInvalidateLock: 0,
};

const rootStore = new Vuex.Store<RootState>({
  // TODO: refactor it to return just the constructor to avoid event-bus and i18n shenanigans; challenge: the singleton management OR add i18n and eventBus here to rootStore instance?  modules: {
  state,
  actions,
  getters,
  mutations,
});

export default rootStore;
