import { Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import RootState from "@lib/types/RootState";
import CheckoutState from "../types/CheckoutState";

export const checkoutStore: Module<CheckoutState, RootState> = {
  namespaced: true,
  state: {
    order: {},
    orderId: "",
    orderPaymentType: "",
    deliveryMode: "",
    shippingSlotId: "",
    dineInSpaceId: "",
    dineInValue: "",
    dineInDetails: {
      spaceId: "",
      value: "",
    },
    personalDetails: {
      fullName: "",
      email: "",
      phone: {
        number: "",
        dialCode: "",
      },
    },
    shippingDetails: {
      fullName: "",
      streetName: "",
      apartment: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      phone: "",
    },
    isThankYouPage: false,
    modifiedAt: 0,
    isOrderCompleted: false,
    useSavedAddress: true,
    selectedAddressId: null,
  },
  getters,
  actions,
  mutations,
};
