import { Store } from "vuex";
import VueRouter from "vue-router";
import Vue from "vue";
import RootState from "@lib/types/RootState";

export type StorefrontModule = (options: {
  app: Vue;
  store: Store<RootState>;
  router: VueRouter;
  moduleConfig: any;
  appConfig: any;
}) => void;

let app: any = {};
let store: Store<any> = null;
let router: VueRouter = null;
let config: any = null;
const registeredModules: StorefrontModule[] = [];

function registerApp(
  clientApp: any,
  clientStore: Store<any>,
  clientRouter: VueRouter,
  clientConfig: any
): void {
  app = clientApp;
  store = clientStore;
  router = clientRouter;
  config = clientConfig;
}

function registerModule(module: StorefrontModule, moduleConfig?: any) {
  if (!registeredModules.includes(module)) {
    module({
      app,
      store,
      router,
      appConfig: config,
      moduleConfig,
    });
    registeredModules.push(module);
    console.debug(`Registered ${module.name}`);
  }
}

function isModuleRegistered(name: string): boolean {
  return registeredModules.some((m) => m.name === name);
}

export {
  app,
  store,
  router,
  config,
  registerApp,
  registerModule,
  isModuleRegistered,
};
