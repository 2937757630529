import StoreConfig from "@lib/types/StoreConfig";
import supportedLocales from "./supportedLocales.json";

const isNonEmptyString = (val) => {
  return val != null && typeof val === "string" && !val.trim();
};

const isValidLocale = (val) => {
  return supportedLocales.indexOf(val) !== -1;
};

const validate = (storeConfig: StoreConfig) => {
  const errors = [];
  if (isNonEmptyString(storeConfig.title)) {
    errors.push("Invalid Title");
  }

  if (storeConfig.localeConfig == null) {
    errors.push("Locale config missing");
  } else {
    const localeConfig = storeConfig.localeConfig;
    if (!isValidLocale(localeConfig.defaultLocale)) {
      errors.push("Locale not supported");
    }

    if (
      localeConfig.priceFormat.indexOf("{sign}") === -1 ||
      localeConfig.priceFormat.indexOf("{amount}") === -1
    ) {
      errors.push("Invalid Price Format");
    }
  }

  if (storeConfig.orderConfig == null) {
    errors.push("Order config missing");
  }

  if (storeConfig.themeConfig == null) {
    errors.push("Theme config missing");
  } else {
    if (storeConfig.themeConfig.themeId !== "default") {
      errors.push("Theme not supported");
    }
  }

  if (storeConfig.contact == null) {
    errors.push("Contact info missing");
  }

  if (errors.length) {
    const errorMessage = errors.join("\n");
    throw new Error(errorMessage);
  }
};

export default validate;
