import { Module } from "vuex";
import {
  createOrderInBusiness,
  createPaymentConfig,
  sendOrderCancelRequest,
} from "../helpers";
import Order from "../types/Order";

export const OrderStore: Module<any, any> = {
  namespaced: true,
  state: {
    userId: null,
    storeId: null,
    list: [],
    paymentProviders: [],
  },
  getters: {
    userId: (state) => state.userId,
    storeId: (state) => state.storeId,
    list: (state) => state.list,
    paymentProviders: (state) => state.paymentProviders,
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId;
    },
    setStoreId(state, storeId) {
      state.storeId = storeId;
    },
    addOrder(state, order: Order) {
      state.list.push(order);
    },
    clearUserOrders(state) {
      state.list = [];
    },
    setUserOrders(state, orders) {
      state.list = orders;
    },
  },
  actions: {
    init({ commit, dispatch, rootState }) {
      commit("setStoreId", rootState.storeId);
      //dispatch("load");
    },
    async createOrder({ getters, dispatch }, order: Order) {
      const sfId = getters.storeId;
      const userId = getters.userId;
      const createdOrder = await createOrderInBusiness(sfId, userId, order);
      if (userId) await dispatch("user/getOrdersHistory", null, { root: true });
      return createdOrder;
    },
    async cancelOrder({ getters, dispatch }, orderId: String) {
      const sfId = getters.storeId;
      const cancelResponse = await sendOrderCancelRequest(sfId, orderId);
      return cancelResponse;
    },
    async createPaymentConfig({ getters, dispatch }, { order, provider }) {
      const sfId = getters.storeId;
      const paymentConfig = await createPaymentConfig(sfId, order, provider);
      return paymentConfig;
    },
    async onLogin({ commit, dispatch }, { userId }) {
      commit("setUserId", userId);
      //await dispatch("fetchOrdersForUser");
    },
    async onLogout({ commit }, payload) {
      commit("setUserId", null);
      commit("clearUserOrders");
    },
    // async fetchOrdersForUser({ commit, state }) {
    //   const ordersSnap = await db
    //     .collection("storefronts")
    //     .doc(state.storeId)
    //     .collection("orders")
    //     .where("user")
    //     .doc(state.userId)
    //     .collection("orders")
    //     .orderBy('name')
    //     .limit(10)
    //     .get();
    //   if (ordersSnap.empty) return;
    //   const orders = ordersSnap.docs.map((x) => ({ ...x.data() }));
    //   commit("setUserOrders", orders);
    // },
    addPaymentProvider({ state }, provider) {
      state.paymentProviders.push(provider);
    },
  },
};
