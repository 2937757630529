import { Module } from "vuex";
import actions from "./actions";
import RootState from "@lib/types/RootState";
import GuestState from "../types/GuestState";

export const guestStore: Module<GuestState, RootState> = {
  namespaced: true,
  state: {},
  actions,
};
