import { ActionTree } from "vuex";
import RootState from "@lib/types/RootState";
import CheckoutState from "../types/CheckoutState";
import { Logger } from "@lib/utility/logger";

const actions: ActionTree<CheckoutState, RootState> = {
  async placeOrder({ dispatch }, { order }) {
    try {
      const orderId = await dispatch("order/placeOrder", order, { root: true });
      if (orderId) {
        await dispatch("cart/clear", null, { root: true });
      }
    } catch (e) {
      Logger.error(e, "checkout")();
    }
  },
};

export default actions;
