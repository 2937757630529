import axios from "axios";
import config from "@config/config";

const sendOrderCancelRequest = async (
  sfId: String,
  orderId: String
): Promise<any> => {
  const resource = `${config.serverlessUrl}order/cancelRequest`;
  const response = await axios.post(resource, {
    sfId,
    orderId,
  });
  return response.data;
};

export default sendOrderCancelRequest;
