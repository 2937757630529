module.exports = {
  environment: "production",
  serverlessUrl: "https://api.zobaze.shop/",
  serverUrl: "/",
  debugMode: false,
  firebaseConfig: {
    apiKey: "AIzaSyBqZgs3cvfSaFjVwyNBSU5w9-NDRGc2ySI",
    authDomain: "zo-front.firebaseapp.com",
    databaseURL: "https://zo-front.firebaseio.com",
    projectId: "zo-front",
    storageBucket: "zo-front.appspot.com",
    messagingSenderId: "554982480971",
    appId: "1:554982480971:web:943e4a52f9e42b1574c1ce",
    measurementId: "G-YF7P1KJDDF",
  },
  fcmVapidKey:
    "BI5PyPz4knB0PFLcMQhwu4klNFuPaVjY51ijPiq1cPD08K_XlfqUxXhhyqiRWg_Qkr07OVO0CPkP0VO-ZOdIK60",
  version: "0.8",
};
