import axios from "axios";
import Order from "../types/Order";
import config from "@config/config";

const createOrderInBusiness = async (
  sfId: string,
  userId: string,
  order: Order
): Promise<any> => {
  const createOrderResource = config.serverlessUrl + "order/create";
  const response = await axios.post(createOrderResource, {
    userId,
    sfId,
    order,
  });
  return response.data;
};

export default createOrderInBusiness;
