import store from "@lib/store";

function getItemsFromStorage({ key }) {
  if (!store.state.storeId) return;
  if (key === `${store.state.storeId}-shop/cart/current-cart`) {
    const storedItems = JSON.parse(localStorage[key]);
    store.dispatch("cart/syncOnLocalStorageChange", {
      items: storedItems,
    });
  }
}

function addEventListener() {
  window.addEventListener("storage", getItemsFromStorage);
}

function removeEventListener() {
  window.removeEventListener("storage", getItemsFromStorage);
}

export { addEventListener, removeEventListener };
