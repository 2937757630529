import { setInitError, initDone } from "./loading";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "@lib/store";
import i18n from "@lib/i18n";
import VueRouter from "vue-router";
import router from "./router";
import { registerIcons } from "./font-awesome";

import { uiStore } from "@theme/store/ui";
import { register } from "@theme/config/registerLibrary";

/* Plugins Start */
import { EventBusPlugin } from "@lib/plugins/event-bus";
import Vuelidate from "vuelidate";
import { VueHammer } from "vue2-hammer";
import vueSmoothScroll from "vue2-smooth-scroll";
import VueScrollPicker from "vue-scroll-picker";
import Ripple from "vue-ripple-directive";
// import VueSocketIOExt from "vue-socket.io-extended";
// import io from "socket.io-client";
// import config from "@config/config";

// const socket = io(config.serverUrl);

// Vue.use(VueSocketIOExt, socket);

Vue.use(VueScrollPicker);
Vue.use(vueSmoothScroll);
Ripple.color = "rgba(0, 0, 0, 0.02)";
Vue.directive("ripple", Ripple);

VueHammer.config.swipe = {
  direction: 6,
};
Vue.use(VueHammer);

Vue.use(EventBusPlugin);
Vue.use(Vuelidate);
Vue.use(VueRouter);

/* Plugins End */

registerIcons(Vue);

declare global {
  interface Window {
    deferredEvent: any;
    sfConfig: any;
    afterConfigHook: any;
    popStateDetected: boolean;
  }
}

window.deferredEvent = window.deferredEvent || null;

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

import storeConfigValidator from "@lib/utility/storeConfigValidator";
import { getCountry } from "@lib/utility/ipUtils";

const startApp = (sfConfig) => {
  try {
    if (sfConfig) {
      storeConfigValidator(sfConfig);
      initDone();
      store.registerModule("ui", uiStore);
      const app = new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
      }).$mount("#app");
      store.dispatch("setStoreConfig", sfConfig);
      store.commit("setLibInitialized", true);
      register(app, store, router);

      if (window.deferredEvent) {
        store.commit("ui/setPwaInstallable", window.deferredEvent);
      } else {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          store.commit("ui/setPwaInstallable", e);
        });
      }

      // For dine in. Todo: Move this out into a separate module
      if (sfConfig.dineInConfig && sfConfig.dineInConfig.dineInEnabled) {
        const querySearchParams = new URL(window.location.href).searchParams;
        const dineInSpaceId = querySearchParams.get("dineInSpaceId");
        const dineInValue = querySearchParams.get("dineInValue");
        if (sfConfig.dineSpaces && sfConfig.dineSpaces.length) {
          if (dineInSpaceId) {
            store.commit("checkout/setDineInSpaceId", dineInSpaceId);
          }
        }

        if (dineInValue) {
          store.commit("checkout/setDineInValue", dineInValue);
        }
      }
      getCountry()
        .then((code) => {
          store.commit("user/setCountryCode", code);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      throw new Error("Invalid Config");
    }
  } catch (error) {
    console.error(error);
    setInitError();
    return;
  }
};

const forceReload = (params) => {
  let baseUrl = window.location.href.split("?")[0];
  if (params) {
    baseUrl = `${baseUrl}?${params}`;
  }
  window.location.replace(baseUrl);
};

const querySearchParams = new URL(window.location.href).searchParams;
const isForceUpdate = querySearchParams.get("forceReload") == "true";
querySearchParams.delete("forceReload");
const newParams = querySearchParams.toString();
if (isForceUpdate) {
  navigator.serviceWorker.getRegistration().then((reg) => {
    if (reg) {
      reg.unregister().then(() => {
        forceReload(newParams);
      });
    } else {
      forceReload(newParams);
    }
  });
}

window.afterConfigHook(startApp);

Vue.config.productionTip = false;
