import { StorefrontModule } from "@lib/appRegister";
import { checkoutStore } from "./store";
//import * as types from "./store/checkout/mutation-types";
//import { StorageManager } from "@vue-storefront/core/lib/storage-manager";

export const CheckoutModule: StorefrontModule = function ({ store }) {
  //StorageManager.init("checkout");

  store.registerModule("checkout", checkoutStore);

  // store.subscribe((mutation, state) => {
  //   const type = mutation.type;

  //   if (type.endsWith(types.CHECKOUT_SAVE_DETAILS)) {
  //     StorageManager.get("checkout").setItem(
  //       "personal-details",
  //       state.checkout.personalDetails
  //     );
  //     StorageManager.get("checkout").setItem(
  //       "personal-details",
  //       state.checkout.personalDetails
  //     );
  //   }

  //   if (
  //     type.endsWith(types.CHECKOUT_SAVE_SHIPPING_DETAILS) ||
  //     type.endsWith(types.CHECKOUT_UPDATE_PROP_VALUE)
  //   ) {
  //     StorageManager.get("checkout")
  //       .setItem("shipping-details", state.checkout.shippingDetails)
  //       .catch((reason) => {
  //         console.error(reason); // it doesn't work on SSR
  //       }); // populate cache
  //   }

  //   if (type.endsWith(types.CHECKOUT_SAVE_PAYMENT_DETAILS)) {
  //     StorageManager.get("checkout")
  //       .setItem("payment-details", state.checkout.paymentDetails)
  //       .catch((reason) => {
  //         console.error(reason); // it doesn't work on SSR
  //       }); // populate cache
  //   }
  // });
};
