import Vue from "vue";
import Init from "./Init.vue";

const init = new Vue({
  render: (h) => h(Init),
  data: {
    loading: true,
    error: false,
    errorMessage: "",
  },
}).$mount("#init");

const setInitError = () => {
  init.loading = false;
  init.error = true;
};

const initDone = () => {
  init.loading = false;
};

export { initDone, setInitError };
