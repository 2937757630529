import * as types from "@lib/modules/cart/store/mutation-types";
import { StorageManager } from "@lib/utility/storageManager";
import CartService from "@lib/services/cart";
import { productsEquals } from "@lib/modules/cart/helpers";
import isEqual from "lodash-es/isEqual";

const synchronizeActions = {
  async load({ commit, dispatch }) {
    const cartStorage = StorageManager.get("cart");
    const storedItems = await cartStorage.getItem("current-cart");
    commit(types.CART_LOAD_CART, storedItems || []);
  },
  syncOnLocalStorageChange({ commit }, { items }) {
    commit(types.CART_LOAD_CART, items || []);
  },
  async sync(
    { getters, commit },
    { forceClientState = true }: { forceClientState?: boolean } = {}
  ) {
    if (!getters.userId) return;
    const serverItems = getters.getServerItems;
    const clientItems = getters.getCartItems;
    const syncedMap = {};
    const updatePromises = [];
    if (forceClientState) {
      // Force client state onto server
      for (const clientItem of clientItems) {
        const serverItem = serverItems.find((item) =>
          productsEquals(item, clientItem)
        );
        if (serverItem === null || !isEqual(clientItem, serverItem)) {
          updatePromises.push(
            CartService.updateItem(getters.storeId, getters.userId, clientItem)
          );
        }
        syncedMap[clientItem.id] = true;
      }

      for (const serverItem of serverItems) {
        if (syncedMap[serverItem.id]) continue;
        updatePromises.push(
          CartService.removeItem(getters.storeId, getters.userId, serverItem)
        );
        commit(types.CART_DEL_ITEM, { product: serverItem });
      }
    } else {
      // Merge client and server (During login)
      for (const clientItem of clientItems) {
        const serverItem = serverItems.find((item) =>
          productsEquals(item, clientItem)
        );
        if (serverItem === null || !isEqual(clientItem, serverItem)) {
          updatePromises.push(
            CartService.updateItem(getters.storeId, getters.userId, {
              ...clientItem,
              qty: serverItem ? serverItem.qty : clientItem.qty,
            })
          );
          commit(types.CART_UPD_ITEM_PROPS, { product: serverItem });
        }
        syncedMap[clientItem.id] = true;
      }

      for (const serverItem of serverItems) {
        if (syncedMap[serverItem.id]) continue;
        updatePromises.push(
          CartService.updateItem(getters.storeId, getters.userId, serverItem)
        );
        commit(types.CART_ADD_ITEM, { product: serverItem });
      }
    }
    await Promise.all(updatePromises);
  },
};

export default synchronizeActions;
