<template>
  <sf-sidebar @close="close" :visible="isSidebarOpen" class="sidebar">
    <div class="sidebar-menu mw-100">
      <div class="sidebar-menu__container row" ref="container">
        <div class="col-xs-12">
          <div class="brand-box">
            <div class="brand-container">
              <div class="logo-container" v-if="storeLogo">
                <div class="logo">
                  <span
                    class="logo-image"
                    :style="{ 'background-image': 'url(' + storeLogo + ')' }"
                    role="img"
                  ></span>
                </div>
              </div>
              <div class="title-container">
                <div class="title">{{ store.title }}</div>
              </div>
            </div>
          </div>
          <ul class="p0 m0 relative sidebar-menu__list h4">
            <li @click="close" class="bg-cl-primary">
              <router-link
                class="block px25 py20 cl-accent no-underline"
                :to="{ name: 'home' }"
                exact
              >
                {{ $t("Home") }}
              </router-link>
            </li>
            <li @click="close" class="bg-cl-primary">
              <router-link
                class="block px25 py20 cl-accent no-underline"
                :to="{ name: 'catalog' }"
                exact
              >
                {{ $t("All Products") }}
              </router-link>
            </li>
            <li class="gutter sub-section">YOUR ACCOUNT</li>
            <li @click="close" class="bg-cl-primary" v-if="!isLoggedIn">
              <a
                href="#"
                @click.prevent="login"
                class="block px25 py20 cl-accent no-underline"
              >
                {{ $t("Login") }}
              </a>
            </li>
            <li @click="close" class="bg-cl-primary" v-if="!isLoggedIn">
              <a
                href="#"
                @click.prevent="signup"
                class="block px25 py20 cl-accent no-underline"
              >
                {{ $t("Signup") }}
              </a>
            </li>
            <li @click="close" class="bg-cl-primary" v-if="isLoggedIn">
              <router-link
                class="block px25 py20 cl-accent no-underline"
                :to="{ name: 'account' }"
                exact
              >
                {{ $t("Your Orders") }}
              </router-link>
            </li>
            <li @click="close" class="bg-cl-primary" v-if="isLoggedIn">
              <a
                href="#"
                @click.prevent="logout"
                class="block px25 py20 cl-accent no-underline"
              >
                {{ $t("Logout") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </sf-sidebar>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { SfSidebar } from "@lib/components";
import config from "@config/config";
import { ModalList } from "@theme/store/ui/modals";

export default {
  components: {
    SfSidebar,
  },
  computed: {
    ...mapState({
      isSidebarOpen: (state) => state.ui.sidebar,
    }),
    ...mapGetters("user", ["isLoggedIn"]),
    ...mapGetters(["store"]),
    storeLogo() {
      return (
        this.store.logo ||
        "https://firebasestorage.googleapis.com/v0/b/zo-front.appspot.com/o/sf%2Fdefaults%2Flogo192.png?alt=media"
      );
    },
  },
  methods: {
    ...mapActions({
      logoutUser: "user/logout",
      openModal: "ui/openModal",
    }),
    close() {
      this.$store.commit("ui/setSidebar");
    },
    login() {
      this.openModal({ name: ModalList.Auth, payload: "login" });
    },
    signup() {
      this.openModal({ name: ModalList.Auth, payload: "signup" });
    },
    myAccount() {
      this.$router.push({
        name: "account",
      });
    },
    myOrders() {
      this.$router.push({
        name: "account",
      });
    },
    logout() {
      this.logoutUser({});
      this.$router.push({ name: "home" }).catch(() => {});
      this.$store.dispatch(
        "notification/spawnNotification",
        {
          type: "success",
          message: "You're logged out",
        },
        { root: true }
      );
    },
  },
};
</script>

<style lang="scss">
@use "~@theme/css/animations/transitions" as *;
@use "~@theme/css/variables/colors" as *;
@use "~@theme/css/helpers/functions/color" as *;
$bg-secondary: color(secondary, $colors-background);
$color-gainsboro: color(gainsboro);
$color-matterhorn: color(matterhorn);
$color-mine-shaft: color(mine-shaft);
@use "~@lib/styles/helpers" as *;

.sidebar {
  --sidebar-content-padding: 0;

  @media (min-width: 1024px) {
    --sidebar-content-padding: 0;
  }

  .brand-box {
    display: flex;
    justify-content: center;
    padding: 1.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .logo-container,
    .title-container {
      display: inline-block;
      height: 50px;
    }

    .logo-container {
      margin-right: 0.5rem;

      .logo {
        display: inline-block;
        position: relative;
        height: 50px;
        width: 50px;
      }

      .logo-image {
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 2px;
        display: flex;
        flex: 1 1 100%;
        height: 100%;
        width: 100%;
      }
    }

    .title-container {
      overflow: hidden;

      .title {
        line-height: 50px;
        font-weight: 500;
        font-size: 24px;
      }
    }
  }

  .sidebar-menu {
    height: 100vh;
    width: 100%;
    overflow: hidden;

    @media (max-width: 767px) {
      width: 100vh;
    }

    &__container {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100% - 55px);
    }

    &__list {
      transition: transform $duration-main $motion-main;
    }

    ul {
      list-style-type: none;
    }

    li {
      &:hover,
      &:focus {
        background-color: $color-gainsboro;
      }
      &.bg-cl-primary {
        &:hover,
        &:focus {
          background-color: $bg-secondary;
        }
      }
      a {
        color: $color-mine-shaft;
      }
    }

    .subcategory-item {
      display: flex;
      width: 100%;
    }

    button {
      color: $color-mine-shaft;
      a {
        color: $color-mine-shaft;
      }
    }

    .close-btn {
      i {
        color: $color-gainsboro;
      }
      &:hover,
      &:focus {
        i {
          color: $color-matterhorn;
        }
      }
    }

    .gutter.sub-section {
      background: rgba(0, 0, 0, 0.08);
      font-size: 0.75rem;
      padding: 16px 8px 4px 8px;
    }
  }

  @include for-mobile {
    --sidebar-width: 75%;
    --sidebar-background: white;
    --bar-background: var(--c-primary);
    --icon-color: white;
  }
}
</style>
