import { GetterTree } from "vuex";
import CheckoutState from "../types/CheckoutState";
import RootState from "@lib/types/RootState";
import calculateTotals from "../helpers/calculateTotals";

const getters: GetterTree<CheckoutState, RootState> = {
  getTotals: (state, getters, rootState, rootGetters) => {
    const storeConfig = rootState.store;
    const cartItems = rootGetters["cart/getCartItems"];
    return calculateTotals(
      cartItems,
      storeConfig.pricingConfig.globalTaxPercentage,
      state.deliveryMode === "ship",
      storeConfig.orderConfig.deliveryCharge,
      storeConfig.orderConfig.packingCharge
    );
  },
  getDeliveryMode: (state) => state.deliveryMode,
  getShippingSlotId: (state) => state.shippingSlotId,
  getDineInDetails: (state) => state.dineInDetails,
  getPersonalDetails: (state) => state.personalDetails,
  getShippingDetails: (state) => state.shippingDetails,
  getIsOrderCompleted: (state) => state.isOrderCompleted,
  getOrderId: (state) => state.orderId,
  useSavedAddress: (state) => state.useSavedAddress,
  selectedAddressId: (state) => state.selectedAddressId,
  orderPaymentType: (state) => state.orderPaymentType,
};

export default getters;
