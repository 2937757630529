import * as types from "@lib/modules/cart/store/mutation-types";
import cartService from "@lib/services/cart";

const connectActions = {
  async clear({ commit, getters }, { sync = false } = {}) {
    commit(types.CART_LOAD_CART, []);
    if (sync && getters.userId) {
      await cartService.clearCart(getters.storeId, getters.userId);
    }
  },
  async onLogin({ getters, dispatch, commit }, { userId }) {
    commit("setUserId", userId);
    commit("clearServerItems");
    await dispatch("fetchCartFromServer");
    await dispatch("sync", { forceClientState: false });
  },
  async onLogout({ getters, commit }) {
    if (getters.userId) {
      commit(types.CART_LOAD_CART, []);
    }
    commit("setUserId", null);
  },
  async fetchCartFromServer({ commit, state }) {
    const serverItems = await cartService.getItems(state.storeId);
    commit("setServerItems", serverItems);
  },
};

export default connectActions;
