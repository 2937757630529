import { customAlphabet } from "nanoid";
const alphaNumeric =
  "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphaNumeric, 24);

export default (size?: number) => {
  if (size) {
    return customAlphabet(alphaNumeric, size)();
  }
  return nanoid();
};
