<template>
  <div class="header">
    <SfOverlay
      class="overlay"
      :visible="isSearchPanelVisible"
      @click="$store.commit('ui/setSearchpanel', false)"
    />
    <header class="fixed w-100" :class="{ 'is-visible': navVisible }">
      <div class="container">
        <div class="container__desktop desktop-only">
          <div class="header__row header__row--main row">
            <div class="col-md-5 header__title-wrapper">
              <hamburger-icon class="header__menu-icon icon pointer" />
              <router-link
                :to="{ name: 'cover', params: { storeId: storeId } }"
                :title="$t('Home Page')"
                class="a-logo header__title no-underline"
              >
                {{ storeTitle }}
              </router-link>
            </div>
            <div class="col-md-2 header__nav-wrapper">
              <router-link
                :to="{ name: 'catalog' }"
                :title="$t('Browse all products')"
                class="a-logo nav__title no-underline"
              >
                All Products
              </router-link>
            </div>
            <div class="col-md-5 header__actions-wrapper">
              <SearchBar class="header__search-bar" />
              <div class="inline-flex right-icons">
                <account-icon class="header__action" />
                <SfButton
                  v-if="hasWhatsapp"
                  class="sf-button--pure header__action"
                  @click="openChatModal"
                >
                  <font-awesome-icon
                    class="fa__icon header__icon"
                    :icon="['fab', 'whatsapp']"
                    style="width: 1.25rem; margin: 0 4px 0 2px"
                  />
                </SfButton>
                <microcart-icon v-if="isCartEnabled" class="header__action" />
              </div>
            </div>
          </div>
        </div>
        <div class="container__mobile mobile-only">
          <div class="header__row header__row--main row">
            <div class="header__title-wrapper">
              <hamburger-icon class="header__menu-icon icon pointer" />
              <router-link
                :to="{ name: 'cover' }"
                :title="$t('Home Page')"
                class="a-logo header__title no-underline"
              >
                {{ storeTitle }}
              </router-link>
            </div>
            <div class="header__actions-wrapper">
              <div class="inline-flex right-icons">
                <pwa-install-icon class="header_action" v-if="showPwaButton" />
                <microcart-icon v-if="isCartEnabled" class="header__action" />
                <SfButton
                  v-if="hasWhatsapp"
                  class="sf-button--pure header__action"
                  @click="openChatModal"
                >
                  <font-awesome-icon
                    class="fa__icon header__icon"
                    :icon="['fab', 'whatsapp']"
                    style="width: 1.25rem; margin: 0 4px 0 2px"
                  />
                </SfButton>
              </div>
            </div>
          </div>
          <div class="row row__search">
            <div class="col-xs-12">
              <SearchBar class="header__search-bar" />
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="header-placeholder" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import MicrocartIcon from "@theme/components/Header/MicrocartIcon";
import AccountIcon from "@theme/components/atoms/AccountIcon";
import PwaInstallIcon from "@theme/components/Header/PwaInstallIcon";
import SearchBar from "@theme/components/Header/SearchBar";
import HamburgerIcon from "@theme/components/Header/HamburgerIcon";
import { SfOverlay, SfButton } from "@lib/components";
import { ModalList } from "@theme/store/ui/modals";

export default {
  name: "Header",
  components: {
    MicrocartIcon,
    AccountIcon,
    SearchBar,
    SfOverlay,
    PwaInstallIcon,
    HamburgerIcon,
    SfButton,
  },
  props: {
    storeTitle: String,
    isCartEnabled: Boolean,
  },
  data() {
    return {
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 54,
      isCheckoutPage: false,
    };
  },
  computed: {
    ...mapState({
      isOpenLogin: (state) => state.ui.signUp,
      currentUser: (state) => state.user.current,
      isSearchPanelVisible: (state) => state.ui.searchpanel,
      storeId: (state) => state.storeId,
    }),
    ...mapGetters("ui", ["showPwaButton"]),
    ...mapGetters(["store"]),
    hasWhatsapp() {
      return !!this.store.contact.whatsappNumber;
    },
    isThankYouPage() {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false;
    },
  },
  beforeMount() {
    window.addEventListener(
      "scroll",
      () => {
        this.isScrolling = true;
      },
      { passive: true }
    );

    setInterval(() => {
      if (this.isScrolling) {
        this.hasScrolled();
        this.isScrolling = false;
      }
    }, 250);
  },
  methods: {
    ...mapActions("ui", {
      openModal: "openModal",
    }),
    gotoAccount() {
      this.$bus.$emit("modal-toggle", "modal-signup");
    },
    hasScrolled() {
      this.scrollTop = window.scrollY;
      let navbarHeight = document.documentElement.clientWidth >= 1024 ? 54 : 92;
      if (
        this.scrollTop > this.lastScrollTop &&
        this.scrollTop > navbarHeight
      ) {
        this.navVisible = false;
      } else {
        this.navVisible = true;
      }
      this.lastScrollTop = this.scrollTop;
    },
    openChatModal() {
      this.openModal({
        name: ModalList.WhatsappHelp,
      });
    },
  },
};
</script>

<style lang="scss">
@use "~@lib/styles/helpers" as *;
@use "~@theme/css/variables/colors" as *;
@use "~@theme/css/helpers/functions/color" as *;

.header {
  $color-icon-hover: color(secondary, $colors-background);

  .container {
    padding: 0;

    &__mobile {
      padding: 0 16px;

      .row {
        margin: 0;
      }

      [class^="col-"] {
        padding: 0;
      }
    }

    &__desktop {
      .header__menu-icon {
        margin-right: 16px;
      }
    }
  }

  &__row {
    &--main {
      height: 54px;
    }
  }

  &__title-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
    align-items: center;
  }

  &__menu-icon {
    padding: 0;
    margin-right: 12px;
  }

  &__title {
    margin-bottom: 1px;
    font-size: 1.05rem;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: Raleway, sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 600;

    &:hover {
      text-decoration: none;
      color: white;
    }

    @include for-desktop {
      width: auto;
      margin-right: 16px;
    }
  }

  &__actions-wrapper {
    display: flex;
    flex: 0 1 0px;
    align-items: center;
  }

  &__nav-wrapper,
  &__search-wrapper {
    display: flex;
    align-items: center;
  }

  &__nav-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: end;
    padding-left: 0;
    height: 100%;
  }

  .nav__title {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    font-family: Raleway, sans-serif;
    color: white;
    font-weight: 400;
    border-bottom: 1px solid #fff;

    &:hover {
      text-decoration: none;
      color: white;
    }

    @include for-desktop {
      width: auto;
      margin-right: 5%;
    }
  }

  &__search-bar {
    padding: 0;
    @include for-desktop {
      margin: 0 8px;
      width: 100%;
    }
  }

  &__icon {
    --icon-color: #ffffff;
    --icon-badge-top: -0.5rem;
    --icon-badge-right: -0.5rem;
    cursor: pointer;
    &:hover {
      --icon-color: white;
    }
    &--is-active {
      --icon-color: white;
    }
  }

  &__action {
    margin: var(--header-action-margin, 0 0 0 var(--spacer-sm));

    @include for-desktop {
      margin: var(--header-action-margin, 0 0 0 var(--spacer-xl));
    }
  }

  header {
    background: var(--header-background, var(--color-primary));
    color: white;
    height: 92px;
    top: -105px;
    z-index: 1;
    transition: top 0.2s ease-in-out;
    &.is-visible {
      top: 0;
    }

    @include for-desktop {
      height: 54px;
      top: -55px;
    }

    .container {
      height: 100%;

      .row {
        &__search {
          height: 38px;
        }
      }
    }
  }
  .icon {
    color: white;
    opacity: 0.9;
    &:hover,
    &:focus {
      // background-color: $color-icon-hover;
      opacity: 1;
    }
  }
  .right-icons {
    //for edge
    float: right;
  }
  .header-placeholder {
    height: 92px;

    @include for-desktop {
      height: 54px;
    }
  }
  .links {
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    .row.middle-xs {
      margin: 0 -15px;

      &.py5 {
        margin: 0;
      }
    }
    .col-xs-2:first-of-type {
      padding-left: 0;
    }
    .col-xs-2:last-of-type {
      padding-right: 0;
    }
  }

  .account-icon-button {
    --icon-color: white;
  }
}

.header.white {
  background: white;
  --header-background: white;
  color: black;

  header {
    color: rgba(0, 0, 0, 0.82);

    .icon,
    .header__title {
      color: rgba(0, 0, 0, 0.82);
      font-weight: bold;
    }

    .header__title {
      color: rgba(0, 0, 0, 0.72);
    }

    .header__icon {
      --icon-color: rgba(0, 0, 0, 0.82);
    }
  }

  header::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 4px;
    background: linear-gradient(
      180deg,
      rgba(9, 30, 66, 0.13) 0,
      rgba(9, 30, 66, 0.13) 1px,
      rgba(9, 30, 66, 0.08) 1px,
      rgba(9, 30, 66, 0) 4px
    );
  }
}
</style>
