export const SN_CART = "cart";
export const CART_ADD_ITEM = SN_CART + "/ADD";
export const CART_DEL_ITEM = SN_CART + "/DEL";
export const CART_DEL_NON_CONFIRMED_ITEM = SN_CART + "/DEL_NONCONFIRMED";
export const CART_UPD_ITEM = SN_CART + "/UPD";
export const CART_LOAD_CART = SN_CART + "/LOAD";
export const CART_UPD_SHIPPING = SN_CART + "/SHIPPING";
export const CART_SAVE = SN_CART + "/SAVE";
export const CART_SET_ITEMS_HASH = SN_CART + "/SAVE_HASH";
export const CART_SET_SYNC = SN_CART + "/MARK_SYNC";
export const CART_SET_TOTALS_SYNC = SN_CART + "/MARK_TOTALS_SYNC";
export const CART_UPD_ITEM_PROPS = SN_CART + "/UPD_PROPS";
export const CART_UPD_TOTALS = SN_CART + "/UPD_TOTALS";
export const CART_LOAD_CART_SERVER_TOKEN = SN_CART + "/SRV_TOKEN";
export const CART_UPD_PAYMENT = SN_CART + "/UPD_PAYMENT";
export const CART_TOGGLE_MICROCART = SN_CART + "/TOGGLE_MICROCART";
export const CART_UPDATE_BYPASS_COUNTER = SN_CART + "/UPD_BYPASS_COUNTER";
export const CART_ADDING_ITEM = SN_CART + "/UPD_ADDING_ITEM";
export const CART_CLEAR_ALL = SN_CART + "/CLEAR_ALL";
