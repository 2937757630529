import Vue from "vue";
import { MutationTree } from "vuex";
import * as types from "./mutation-types";
import CartState from "../types/CartState";
import EventBus from "@lib/plugins/event-bus";
import productsEquals from "./../helpers/productsEquals";

const mutations: MutationTree<CartState> = {
  /**
   * Add product to cart
   * @param {Object} product data format for products is described in /doc/ElasticSearch data formats.md
   */
  [types.CART_ADD_ITEM](state, { product }) {
    const record = state.cartItems.find((p) => productsEquals(p, product));
    if (!record) {
      const item = {
        ...product,
        qty: product.qty ? product.qty : 1,
      };
      state.cartItems.push(item);
    } else {
      record.qty += product.qty ? product.qty : 1;
    }
  },
  [types.CART_DEL_ITEM](state, { product }) {
    state.cartItems = state.cartItems.filter(
      (p) => !productsEquals(p, product)
    );
  },
  [types.CART_UPD_ITEM](state, { product, qty }) {
    const record = state.cartItems.find((p) => productsEquals(p, product));

    if (record) {
      record.qty = qty;
    }
  },
  [types.CART_UPD_ITEM_PROPS](state, { product }) {
    const record = state.cartItems.find((p) => productsEquals(p, product));
    if (record) {
      EventBus.$emit("cart-before-itemchanged", { item: record });
      Object.entries(product).forEach(([key, value]) =>
        Vue.set(record, key, value)
      );
      EventBus.$emit("cart-after-itemchanged", { item: record });
    }
  },
  [types.CART_LOAD_CART](state, storedItems) {
    storedItems = storedItems.map((x) => {
      x.basePrice = x.price.mrp || x.price.regular;
      x.discountedPrice = x.price.mrp
        ? x.price.special || x.price.regular
        : x.price.special || null;
      x.finalPrice = x.discountedPrice || x.basePrice;
      return x;
    });
    state.cartItems = storedItems || [];
    state.cartIsLoaded = true;
  },
  [types.CART_TOGGLE_MICROCART](state) {
    state.isMicrocartOpen = !state.isMicrocartOpen;
  },
  [types.CART_UPDATE_BYPASS_COUNTER](state, { counter }) {
    state.connectBypassCount = state.connectBypassCount + counter;
  },
  [types.CART_ADDING_ITEM](state, { isAdding }) {
    state.isAddingToCart = isAdding;
  },
  [types.CART_CLEAR_ALL](state) {
    // do nothing
  },
  [types.CART_LOAD_CART_SERVER_TOKEN](state, token) {
    state.cartServerToken = token;
  },
  [types.CART_SET_SYNC](state) {
    state.cartServerLastSyncDate = new Date().getTime();
  },
  productDocChange(state, product) {
    // const { doc, type } = change;
    // const product = doc.data();
    // if (type === "removed") {
    //   product.removed = true;
    // }
    const record = state.cartItems.find((p) => productsEquals(p, product));
    if (record) {
      record.basePrice = record.price.mrp || record.price.regular;
      record.discountedPrice = record.price.mrp
        ? record.price.special || record.price.regular
        : record.price.special || null;
      record.finalPrice = record.discountedPrice || record.basePrice;
      record.qty = product.isFraction
        ? record.qty || 1
        : Math.floor(record.qty) || 1;
      Object.entries(product).forEach(([key, value]) =>
        Vue.set(record, key, value)
      );
    }
  },
  setUserId(state, userId) {
    state.userId = userId;
  },
  setStoreId(state, storeId) {
    state.storeId = storeId;
  },
  setServerItems(state, items) {
    state.serverItems = items;
  },
  clearServerItems(state) {
    state.serverItems = [];
  },
  serverCartItemChanged(state, change) {
    const { newIndex, oldIndex, doc, type } = change;
    if (type === "added") {
      const product = doc.data();
      state.serverItems.splice(newIndex, 0, product);
    } else if (type === "modified") {
      const product = doc.data();
      state.serverItems.splice(oldIndex, 1);
      state.serverItems.splice(newIndex, 0, product);
    } else if (type === "removed") {
      state.serverItems.splice(oldIndex, 1);
    }
  },
};

export default mutations;
