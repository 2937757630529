import * as types from "../store/mutation-types";
import { StorageManager } from "@lib/utility/storageManager";

export function cartCacheHandlerFactory(Vue) {
  return (mutation, state) => {
    const type = mutation.type;

    if (
      type.endsWith(types.CART_LOAD_CART) ||
      type.endsWith(types.CART_ADD_ITEM) ||
      type.endsWith(types.CART_DEL_ITEM) ||
      type.endsWith(types.CART_UPD_ITEM) ||
      type.endsWith(types.CART_DEL_NON_CONFIRMED_ITEM) ||
      type.endsWith(types.CART_UPD_ITEM_PROPS) ||
      type.endsWith("productDocChange")
    ) {
      return StorageManager.get("cart").setItem(
        "current-cart",
        state.cart.cartItems
      );
    }
  };
}
