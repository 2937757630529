import { Logger } from "@lib/utility/logger";
import { createInstance, LOCALSTORAGE } from "localforage";
import UniversalStorage from "@lib/utility/storageDriver";
import store from "@lib/store";

function _prepareCacheStorage(key) {
  return new UniversalStorage(
    createInstance({
      name: `${store.state.storeId}-shop`,
      storeName: key,
      driver: LOCALSTORAGE,
    })
  );
}

const StorageManager = {
  keyMap: {},
  init: function (key: string) {
    this.keyMap[key] = _prepareCacheStorage(key);
    return this.keyMap[key];
  },
  set: function (
    key: string,
    storageInstance: UniversalStorage
  ): UniversalStorage {
    this.keyMap[key] = storageInstance;
    return storageInstance;
  },
  exists(key): boolean {
    return !!this.keyMap[key];
  },
  get: function (key): UniversalStorage {
    if (!this.exists(key)) {
      Logger.warn(
        "Called cache collection " + key + " does not exist. Initializing.",
        "cache"
      );
      return this.set(key, _prepareCacheStorage(key));
    } else {
      return this.keyMap[key];
    }
  },
};

export { StorageManager };
