import Vue from "vue";
import { StorefrontModule } from "@lib/appRegister";
import { cartStore } from "./store";
import { StorageManager } from "@lib/utility/storageManager";
import { cartCacheHandlerFactory } from "./helpers";

export const CartModule: StorefrontModule = function ({ store }) {
  StorageManager.init("cart");
  store.registerModule("cart", cartStore);

  store.dispatch("cart/init");
  store.subscribe(cartCacheHandlerFactory(Vue));
};
