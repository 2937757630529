/* eslint-disable no-console */

import { register } from "register-service-worker";

declare global {
  interface Window {
    pwaUpdates: any;
  }
}

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
  },
  registered(registration) {
    console.log("SW registered.");
    setInterval(() => {
      console.debug("Checking for SW updates");
      registration.update();
    }, 1000 * 60 * 60);
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound(reg) {
    console.log("Update found! Downloading...");
  },
  updated(registration) {
    console.log("Update downloaded; reloading...");
    // window.pwaUpdates = {
    //   registration,
    //   available: true,
    // };
    // document.dispatchEvent(
    //   new CustomEvent("swUpdated", { detail: registration })
    // );
  },
  offline() {
    console.log("No internet connection found. App is in offline mode.");
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
