<template>
  <div id="app">
    <button class="update-button" v-if="updateExists" @click="refreshApp">
      New version available! Click to update
    </button>
    <component
      :is="layout"
      :header-type="headerType"
      :has-bottom-nav="hasBottomNav"
    >
      <router-view />
    </component>
  </div>
</template>

<script>
import get from "lodash-es/get";
import { mapState, mapActions } from "vuex";
import HomeLayout from "./layout/home";
import BasicLayout from "./layout/basic";
import axios from "axios";
import { notifications } from "@lib/modules/cart/helpers";

export default {
  components: {
    HomeLayout,
    BasicLayout,
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  computed: {
    ...mapState(["storeId"]),
    layout() {
      return `${get(this.$route, "meta.layout", "home")}-layout`;
    },
    headerType() {
      return get(this.$route, "meta.header", "main");
    },
    hasBottomNav() {
      return get(this.$route, "meta.hasBottomNav", "main");
    },
  },
  methods: {
    ...mapActions({
      logoutUser: "user/logout",
    }),
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      console.log("Forcing update");
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
  async created() {
    if (window.pwaUpdates && window.pwaUpdates.available) {
      this.showRefreshUI({ detail: window.pwaUpdates.registration });
    } else {
      document.addEventListener("swUpdated", this.showRefreshUI, {
        once: true,
      });
    }
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  async beforeCreate() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.indexOf &&
          error.response.data.indexOf("access token expired") !== -1
        ) {
          this.logoutUser({});
          let notification = notifications.createNotification({
            type: "warning",
            message: `Your session has expired. Please re-login into your account.`,
          });
          this.$store.dispatch("notification/spawnNotification", notification);
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );
  },
  async mounted() {
    const self = this;
    axios.interceptors.request.use(
      async (config) => {
        if (self.$store.state.user.userToken) {
          config.headers[
            "Authorization"
          ] = `Bearer ${self.$store.state.user.userToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  },
};
</script>

<style lang="scss" src="@lib/styles.scss"></style>
<style lang="scss" src="@theme/css/main.scss"></style>

<style lang="scss">
@use "~@lib/styles/helpers/breakpoints" as *;

body {
  --overlay-z-index: 1;
  --sidebar-aside-z-index: 2;
  --bottom-navigation-height: 3.75rem;
  --bar-height: 3.125rem;
  --notification-font-size: var(--font-sm);
  font-family: var(--font-family-secondary);
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    color: var(--c-link);
    cursor: pointer;
    &:hover {
      color: var(--c-link-hover);
    }
  }

  .update-button {
    position: fixed;
    z-index: 2;
    padding: 1rem 3rem;
    background-color: #444;
    color: white;
    border: none;
    right: 0;
    height: 54px;

    @include for-mobile {
      width: 100%;
    }
  }
}
</style>
