/**
 * List of modal names
 */
export enum ModalList {
  Review = "modal-review",
  Auth = "modal-authentication",
  VerifyPhoneNumber = "modal-verify-phone",
  UpdateFullName = "modal-update-name",
  AddEmail = "modal-add-email",
  SizeGuide = "modal-size-guide",
  TermsAndConditions = "modal-terms-and-conditions",
  FeatureNotImplemented = "modal-feature-not-implemented",
  LanguageSwitcher = "modal-language-switcher",
  OrderConfirmation = "modal-order-confirmation",
  Newsletter = "modal-newsletter",
  OrderCancelRequest = "modal-order-cancel-request",
  OrderHelp = "modal-order-help",
  WhatsappHelp = "modal-whatsapp-help",
}

/**
 * List of modal components files
 */
export const modalComponents = new Map([
  [
    ModalList.Auth,
    (): any =>
      import(
        /* webpackChunkName: "vsf-modals" */ "@theme/components/molecules/modals/modal-authentication.vue"
      ),
  ],
  [
    ModalList.VerifyPhoneNumber,
    (): any =>
      import(
        /* webpackChunkName: "vsf-modals" */ "@theme/components/molecules/modals/modal-verify-phone.vue"
      ),
  ],
  [
    ModalList.AddEmail,
    (): any =>
      import(
        /* webpackChunkName: "vsf-modals" */ "@theme/components/molecules/modals/modal-add-email.vue"
      ),
  ],
  [
    ModalList.UpdateFullName,
    (): any =>
      import(
        /* webpackChunkName: "vsf-modals" */ "@theme/components/molecules/modals/modal-update-name.vue"
      ),
  ],
  [
    ModalList.OrderCancelRequest,
    (): any =>
      import(
        /* webpackChunkName: "vsf-modals" */ "@theme/components/order/organisms/modal-order-cancel.vue"
      ),
  ],
  [
    ModalList.OrderHelp,
    (): any =>
      import(
        /* webpackChunkName: "vsf-modals" */ "@theme/components/order/organisms/modal-order-help.vue"
      ),
  ],
  [
    ModalList.WhatsappHelp,
    (): any =>
      import(
        /* webpackChunkName: "vsf-modals" */ "@theme/components/molecules/modal-whatsapp-help.vue"
      ),
  ],
]);

/**
 * Optional payload for modal
 */
export const modalPayload = new Map([]);

export const modalStore = {
  state: {
    activeModals: [],
    loadedModals: [],
  },
  getters: {
    activeModals: (state) =>
      state.activeModals.map((name) => ({
        name,
        payload: modalPayload.get(name),
      })),
    loadedModals: (state) => state.loadedModals,
  },
  mutations: {
    openModal(state, name) {
      if (!state.loadedModals.includes(name)) {
        state.loadedModals = [...state.loadedModals, name];
      }
      state.activeModals = [...state.activeModals, name];
    },
    closeModal(state, name) {
      state.activeModals = state.activeModals.filter((modal) => modal !== name);
    },
  },
  actions: {
    openModal({ commit }, { name, payload }) {
      modalPayload.set(name, payload);
      commit("closeModal", name);
      commit("openModal", name);
    },
    closeModal({ commit }, { name }) {
      modalPayload.delete(name);
      commit("closeModal", name);
    },
  },
};
