import { ActionTree } from "vuex";
import * as types from "./mutation-types";
import RootState from "@lib/types/RootState";
import StoreConfig from "@lib/types/StoreConfig";
import storeConfigValidator from "@lib/utility/storeConfigValidator";
import { getCountry } from "@lib/utility/ipUtils";

const actions: ActionTree<RootState, RootState> = {
  async resetUserInvalidateLock({ commit }) {
    commit(types.USER_TOKEN_INVALIDATE_LOCK_CHANGED, 0);
  },
  async setStoreConfig({ commit }, storeConfig) {
    commit("setStoreId", storeConfig.id);
    commit("setStore", storeConfig);
  },
  async setStoreNotSpecified({ commit }, payload) {
    commit("setStoreNotSpecified", payload);
  },
  async fetchCountryCode({ commit }) {
    const countryCode = await getCountry();
    commit("setCountryCode", countryCode);
  },
};

export default actions;
