<template>
  <SfButton class="sf-button--pure microcart-icon" @click="openMicrocart">
    <SfIcon
      icon="empty_cart"
      :has-badge="!!numberOfItems"
      :badge-label="numberOfItems + ''"
    >
    </SfIcon>
  </SfButton>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { syncOnLocalStorageChange } from "@lib/modules/cart/helpers";

import { SfIcon, SfButton } from "@lib/components";

export default {
  components: { SfIcon, SfButton },
  mounted() {
    syncOnLocalStorageChange.addEventListener();
    this.$once("hook:beforeDestroy", () => {
      syncOnLocalStorageChange.removeEventListener();
    });
  },
  computed: {
    ...mapGetters({
      numberOfItems: "cart/getNumberOfItems",
    }),
  },
  methods: {
    openMicrocart() {
      this.$store.dispatch("ui/toggleMicrocart");
    },
  },
  methods: {
    ...mapActions({
      openMicrocart: "ui/toggleMicrocart",
    }),
  },
};
</script>

<style lang="scss" scoped>
.sf-icon {
  --icon-badge-top: -0.5rem;
  --icon-badge-right: -0.5rem;
  position: relative;
  .header__icon {
    cursor: pointer;
  }
}
</style>
