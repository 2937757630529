import axios from "axios";
import config from "@config/config";

const getAll = async (storeId) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const catalogResource = config.serverUrl + "api/catalog";

  const response = await axios.get(catalogResource);
  return response.data;
};

export default { getAll };
