<template>
  <div class="collected-product-list-item">
    <ProductListItem
      :product="selectedProduct"
      :is-cart-enabled="isCartEnabled"
      :show-stock-errors="showStockErrors"
      :link="'/p/' + selectedProduct.variantId"
    />
    <div class="variant-tags" v-if="products.length > 1">
      <ul>
        <li
          v-for="product in products"
          :key="product.variantId"
          :class="[
            selectedProduct.id === product.id ? 'selected' : '',
            'variant-tags__item',
          ]"
          @click="selectedProduct = product"
        >
          <span v-if="product.variantName">
            {{ product.variantName }}
          </span>
          <span v-else>
            <SfPrice :regular="product.basePrice | price" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ProductListItem from "./ProductListItem";
import SfPrice from "../atoms/SfPrice/SfPrice.vue";
export default {
  components: {
    ProductListItem,
    SfPrice,
  },
  data() {
    return {
      selectedProduct: this.products[0],
    };
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    imageWidth: {
      type: [String, Number],
      default: 200,
    },
    imageHeight: {
      type: [String, Number],
      default: 200,
    },
    link: {
      type: [String, Object],
      default: "",
    },
    linkTag: {
      type: String,
      default: undefined,
    },
    isCartEnabled: {
      type: Boolean,
      default: true,
    },
    showStockErrors: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/helpers" as *;

.collected-product-list-item {
  background-color: var(--product-card-background, var(--c-white));

  .product-list-item {
    &:hover {
      --product-card-box-shadow: none;
    }
  }

  &:hover {
    box-shadow: 0px 4px 35px rgba(168, 172, 176, 0.19);
  }

  flex-direction: column;
  .variant-tags {
    padding: var(--spacer-sm) 0 0 0;
    &__item {
      cursor: pointer;
    }

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        padding: 0.25rem;
        background: white;
        border: 1px solid var(--c-primary);
        color: var(--c-primary);
        border-radius: 0.25rem;
        margin-right: 0.25rem;
        margin-top: 0.12rem;
        font-size: 0.85rem;
        --price-font-size: 0.85rem;
        --price-font-line-height: 1.2rem;
        --price-color: var(--c-primary);
        font-weight: var(--font-medium);
        font-family: var(--font-family-primary);

        &:last-of-type {
          margin-right: 0;
        }

        &.selected {
          color: white;
          --price-color: white;
          background: var(--c-primary);
        }
      }
    }

    @include for-desktop {
      padding: 0 var(--spacer-sm) var(--spacer-sm) var(--spacer-sm);
    }
  }
}
</style>
