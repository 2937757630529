import { modalStore } from "./ui/modals";

export const uiStore = {
  namespaced: true,
  modules: {
    modal: modalStore,
  },
  state: {
    sidebar: false,
    microcart: false,
    wishlist: false,
    searchpanel: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: "login",
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: [],
    },
    canInstallPwa: false,
    deferredPrompt: null,
    pwaModalDismissed: false,
    pwaInstallTriggered: false,
    notificationsEnabled: false,
  },
  getters: {
    canInstallPwa: (state) => state.canInstallPwa,
    showPwaModal: (state) => {
      // let lastShown = sessionStorage.getItem("pwaPopupLastShown");
      // let canShowPopup = false;
      // if (lastShown == null) {
      //   lastShown = Date.now().toString();
      //   canShowPopup = true;
      // } else {
      //   const lastShownTs = Number(lastShown);
      //   if (Date.now() - lastShownTs > 60 * 1000) {
      //     lastShown = Date.now().toString();
      //     canShowPopup = true;
      //   }
      // }
      // if (canShowPopup) {
      //   sessionStorage.setItem("pwaPopupLastShown", lastShown);
      //   return (
      //     state.canInstallPwa &&
      //     !state.pwaModalDismissed &&
      //     !state.pwaInstallTriggered
      //   );
      // }
      //return false;
      return (
        state.canInstallPwa &&
        !state.pwaModalDismissed &&
        !state.pwaInstallTriggered
      );
    },
    showPwaButton: (state) => state.canInstallPwa && !state.pwaInstallTriggered,
    notificationsEnabled: (state) => state.notificationsEnabled,
  },
  mutations: {
    setCheckoutMode(state, action) {
      state.checkoutMode = action === true;
    },
    setMicrocart(state, action) {
      state.microcart = action === true;
      state.overlay = action === true;
    },
    setSidebar(state, action) {
      state.sidebar = action === true;
      state.overlay = action === true;
    },
    setSubmenu(state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id);
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop();
        }, 300);
      }
      state.submenu.depth = state.submenu.depth > 0 && depth;
    },
    setSearchpanel(state, action) {
      state.searchpanel = action === true;
      state.overlay = action === true;
    },
    setWishlist(state, action) {
      state.wishlist = action === true;
      state.overlay = action === true;
    },
    setOverlay(state, action) {
      state.overlay = action === true;
    },
    setLoader(state, action) {
      state.loader = action === true;
    },
    setAuthElem(state, action) {
      state.authElem = action;
    },
    setPwaInstallable(state, deferredPrompt) {
      state.deferredPrompt = deferredPrompt;
      state.canInstallPwa = true;
    },
    setNotificationsEnabled(state, isEnabled) {
      state.notificationsEnabled = isEnabled;
    },
  },
  actions: {
    toggleMicrocart({ commit, state }) {
      commit("setMicrocart", !state.microcart);
    },
    toggleWishlist({ commit, state }) {
      commit("setWishlist", !state.wishlist);
    },
    triggerAddToHomeScreen({ commit, state }) {
      state.deferredPrompt.prompt();
      state.pwaInstallTriggered = true;
      // deferredPrompt.userChoice.then(function(choiceResult){

      // })
    },
    dismissPwaModal({ state }) {
      state.pwaModalDismissed = true;
    },
  },
};
