import axios from "axios";
import Order from "../types/Order";
import config from "@config/config";

const createPaymentConfig = async (
  sfId: string,
  order: Order,
  provider: any
): Promise<any> => {
  const createOrderResource = `${config.serverlessUrl}order/paymentConfig`;
  const response = await axios.post(createOrderResource, {
    sfId,
    order,
    provider,
  });
  return response.data;
};

export default createPaymentConfig;
