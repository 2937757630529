<template>
  <div class="basic-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "basic-layout",
};
</script>

<style>
.basic-layout {
  min-height: 100vh;
  background: #f7f7f7;
  --sidebar-z-index: 5;
}
</style>
