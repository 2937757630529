import { ActionTree } from "vuex";
import RootState from "@lib/types/RootState";
import GuestState from "../types/GuestState";
import GuestService from "@lib/services/guest";

const actions: ActionTree<GuestState, RootState> = {
  async load({ rootGetters, dispatch }) {
    // Do nothing
  },
  async getOrderUsingNotificationToken({}, { orderId, token }) {
    try {
      const {
        success,
        order,
        errorMessage,
      } = await GuestService.getOrderFromNotificationToken(orderId, token);
      return { success, order, errorMessage };
    } catch (error) {
      return { success: false, errorMessage: "Could not fetch your order" };
    }
  },
};

export default actions;
