import * as types from "@lib/modules/cart/store/mutation-types";
import { Logger } from "@lib/utility/logger";
import {
  productsEquals,
  validateProduct,
  createDiffLog,
  notifications,
} from "@lib/modules/cart/helpers";
import { cartHooksExecutors } from "@lib/modules/cart/hooks";

const itemActions = {
  async updateItem({ commit, getters, dispatch }, { product }) {
    commit(types.CART_UPD_ITEM_PROPS, { product });
    if (getters.userId) {
      await dispatch("sync");
    }
  },
  getItem({ getters }, { product }) {
    return getters.getCartItems.find((p) => productsEquals(p, product));
  },
  async addItem({ dispatch, commit }, { productToAdd }) {
    const { cartItem } = cartHooksExecutors.beforeAddToCart({
      cartItem: productToAdd,
    });
    commit(types.CART_ADDING_ITEM, { isAdding: true });
    const result = await dispatch("addItems", {
      productsToAdd: [cartItem],
    });
    commit(types.CART_ADDING_ITEM, { isAdding: false });
    cartHooksExecutors.afterAddToCart(result);
    return result;
  },
  async checkProductStatus({ dispatch, getters }, { product }) {
    const record = getters.getCartItems.find((p) => productsEquals(p, product));
    const qty = record
      ? record.qty + product.qty
      : product.qty
      ? product.qty
      : 1;

    const checkStatus = {
      qty,
      status: await dispatch(
        "catalog/checkStock",
        { productId: product.id, requiredStock: qty },
        { root: true }
      ),
    };

    return {
      ...checkStatus,
      status: checkStatus.status,
    };
  },
  async addItems(
    { commit, dispatch, getters, rootGetters },
    { productsToAdd }
  ) {
    let productIndex = 0;
    const diffLog = createDiffLog();
    for (const product of productsToAdd) {
      const errors = validateProduct(product);
      diffLog.pushNotifications(
        notifications.createNotifications({ type: "error", messages: errors })
      );
      const ignoreStockErrors =
        rootGetters.store.orderConfig.allowOrderOnNoStock;
      if (errors.length === 0) {
        if (!ignoreStockErrors) {
          const { status } = await dispatch("checkProductStatus", { product });
          if (status === "out_of_stock") {
            diffLog.pushNotification(notifications.outOfStock());
          } else if (status === "ok") {
            commit(types.CART_ADD_ITEM, {
              product,
            });
            if (productIndex === productsToAdd.length - 1) {
              diffLog.pushNotification(notifications.productAddedToCart());
            }
            productIndex++;
          }
        } else {
          commit(types.CART_ADD_ITEM, {
            product,
          });
          if (productIndex === productsToAdd.length - 1) {
            diffLog.pushNotification(notifications.productAddedToCart());
          }
          productIndex++;
        }
      }
    }
    dispatch("sync");
    return diffLog;
  },
  async removeItem({ commit, dispatch, getters }, payload) {
    const removeByParentSku = payload.product
      ? !!payload.removeByParentSku && payload.product.type_id !== "bundle"
      : true;
    const product = payload.product || payload;
    const { cartItem } = cartHooksExecutors.beforeRemoveFromCart({
      cartItem: product,
    });

    commit(types.CART_DEL_ITEM, { product: cartItem, removeByParentSku });

    const diffLog = createDiffLog().pushClientParty({
      status: "no-item",
      sku: product.sku,
    });
    cartHooksExecutors.afterRemoveFromCart(diffLog);
    await dispatch("sync");
    return diffLog;
  },
  async updateQuantity({ commit, dispatch, getters }, { product, qty }) {
    commit(types.CART_UPD_ITEM, { product, qty });
    await dispatch("sync");
    return createDiffLog().pushClientParty({
      status: "wrong-qty",
      sku: product.sku,
      "client-qty": qty,
    });
  },
  async updateProduct({ commit, dispatch }, product) {
    commit("productDocChange", product);
    //await dispatch("sync");
  },
};

export default itemActions;
