import axios from "axios";
import config from "@config/config";
import { getDeviceId } from "@lib/modules/analytics/services";

const updateInstanceId = async (fcmToken, userId) => {
  const resource = config.serverUrl + "api/instance/updateFcmToken";
  const deviceId = getDeviceId();
  console.log({ deviceId });
  await axios.post(resource, {
    fcmToken: fcmToken,
    deviceId,
    userId,
  });
};

export { updateInstanceId };
