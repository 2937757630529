import Vue from "vue";
import VueI18n from "vue-i18n";
import { Logger } from "@lib/utility/logger";

Vue.use(VueI18n);

const loadedLanguages = ["en-US"];
const i18n = new VueI18n({
  locale: "en-US",
  fallbackLocale: "en-US",
  messages: {
    "en-US": require("./resource/i18n/en-US.json"),
  },
});

function setI18nLanguage(lang: string): string {
  i18n.locale = lang;
  return lang;
}

/**
 * Lazy load date locales file for current switched language.
 */
// const loadDateLocales = async (lang: string = 'en'): Promise<void> => {
//   let localeCode = lang.toLocaleLowerCase()
//   try { // try to load full locale name
//     await import(/* webpackChunkName: "dayjs-locales-[request]" */ `dayjs/locale/${localeCode}`)
//   } catch (e) { // load simplified locale name, example: de-DE -> de
//     const separatorIndex = localeCode.indexOf('-')
//     if (separatorIndex) {
//       localeCode = separatorIndex ? localeCode.substr(0, separatorIndex) : localeCode
//       try {
//         await import(/* webpackChunkName: "dayjs-locales-[request]" */ `dayjs/locale/${localeCode}`)
//       } catch (err) {
//         Logger.debug('Unable to load translation from dayjs')()
//       }
//     }
//   }
// }

export async function loadLanguageAsync(lang: string): Promise<string> {
  //await loadDateLocales(lang)
  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}

export default i18n;
