import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import config from "@config/config";
import "firebase/performance";

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
  if (config.environment === "production" || config.environment === "shopman_production") firebase.analytics();
}

export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

firebase.performance();

export default firebase;
