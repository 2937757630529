<template>
  <div class="search">
    <div class="search-bar__wrapper">
      <input
        v-model="search"
        class="search-bar__input"
        type="search"
        placeholder="Search for Products"
        @input="startSearch"
        @search="closeOnEmpty()"
        @click="$store.commit('ui/setSearchpanel', true)"
      />
    </div>
    <SearchPanel
      v-if="isOpen"
      :search="search"
      :products="products"
      :is-grouped-view="isGroupedView"
      :is-cart-enabled="isCartEnabled"
      :show-stock-errors="showStockErrors"
      @close="$store.commit('ui/setSearchpanel', false)"
      @reload="reloadComponent()"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SearchPanel from "@theme/components/organisms/SearchPanel";
import fuzzysort from "fuzzysort";

export default {
  name: "SearchBar",
  components: {
    SearchPanel,
  },
  data() {
    return {
      search: "",
      productSearchResultIds: [],
    };
  },
  watch: {
    isOpen: function (val) {
      this.search = val ? this.search : "";
    },
  },
  computed: {
    ...mapGetters({
      productList: "catalog/list",
      categoryList: "catalog/categories",
      store: "store",
    }),
    ...mapState({
      isOpen: (state) => state.ui.searchpanel,
    }),
    products() {
      return this.productSearchResultIds.map((x) =>
        this.productList.find((p) => p.id === x)
      );
    },
    isCartEnabled() {
      return (
        this.store.orderConfig.orderEnabled ||
        this.store.orderConfig.enquiryEnabled
      );
    },
    showStockErrors() {
      return !this.store.orderConfig.allowOrderOnNoStock;
    },
    isGroupedView() {
      return this.store.catalog && this.store.catalog.enableVariantGrouping;
    },
  },
  methods: {
    startSearch() {
      if (this.search.length >= 3) {
        this.makeSearch();
      }
    },
    toggleSearchpanel() {
      this.$store.commit("ui/setSearchpanel", !this.isOpen);
    },
    closeOnEmpty() {
      if (!this.search) {
        this.$store.commit("ui/setSearchpanel", false);
      }
    },
    onEscapePress() {
      this.closeSearchpanel();
    },
    closeSearchpanel() {
      this.$store.commit("ui/setSidebar", false);
      this.$store.commit("ui/setMicrocart", false);
      this.$store.commit("ui/setSearchpanel", false);
    },
    buildSearchQuery(queryText) {
      let searchQuery = prepareQuickSearchQuery(queryText);
      return searchQuery;
    },
    makeSearch() {
      if (this.search !== "" && this.search !== undefined) {
        const options = {
          limit: 10,
          keys: ["title", "variantName", "categoryName"],
        };
        this.productSearchResultIds = fuzzysort
          .go(this.search, this.productList, options)
          .map((x) => x.obj.id);
      } else {
        this.productSearchResultIds = [];
      }
    },
  },
};
</script>

<style lang="scss">
.search {
  padding: 0 8px;
}

.search-bar {
  &__wrapper {
    display: inline-block;
    width: 100%;
    height: 28px;
    font-size: 14px;
    letter-spacing: -0.2px;
    text-align: center;
    color: #777;
    display: inline-block;
    text-align: left;
    opacity: 1;
    vertical-align: middle;
    border-radius: 2px;
    background-color: #fff;
    position: relative;
    line-height: 28px;
    border: 0;
    outline: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 0.33rem;

    &:before {
      position: absolute;
      content: " ";
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzg3ODc4NyIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTEuNjE4IDkuODk3bDQuMjI1IDQuMjEyYy4wOTIuMDkyLjEwMS4yMzIuMDIuMzEzbC0xLjQ2NSAxLjQ2Yy0uMDgxLjA4MS0uMjIxLjA3Mi0uMzE0LS4wMmwtNC4yMTYtNC4yMDMiLz48cGF0aCBkPSJNNi40ODYgMTAuOTAxYTQuMzc0IDQuMzc0IDAgMDEtNC4zODEtNC4zNjggNC4zNzUgNC4zNzUgMCAwMTQuMzgxLTQuMzY5IDQuMzc1IDQuMzc1IDAgMDE0LjM4MSA0LjM2OSA0LjM3NCA0LjM3NCAwIDAxLTQuMzgxIDQuMzY4bTAtMTAuODM1QzIuOTA0LjA2NiAwIDIuOTYxIDAgNi41MzMgMCAxMC4xMDUgMi45MDQgMTMgNi40ODYgMTNzNi40ODYtMi44OTUgNi40ODYtNi40NjdjMC0zLjU3Mi0yLjkwNC02LjQ2Ny02LjQ4Ni02LjQ2NyIvPjwvZz48L3N2Zz4=);
      height: 16px;
      width: 16px;
      left: 10px;
      top: 6px;
    }
  }

  &__input {
    width: 100%;
    height: 26px;
    outline: 0;
    padding-left: 36px;
    font-size: 14px;
    border: 0;
    outline: none;
  }
}

.search {
  &.grey {
    input {
      background: rgba(0, 0, 0, 0.11);

      &::placeholder {
        color: rgba(0, 0, 0, 0.72);
      }
    }
  }
}
</style>
