<template>
  <div class="sf-quantity-selector__wrapper">
    <div class="sf-quantity-selector" v-if="!isFraction">
      <SfButton
        :disabled="disabled"
        class="sf-quantity-selector__button sf-quantity-selector__minus"
        @click.prevent="decrement"
      >
        <font-awesome-icon :icon="['fas', 'minus']" />
      </SfButton>
      <SfInput
        type="number"
        :value="qty"
        v-bind="$attrs"
        :disabled="disabled"
        class="sf-quantity-selector__input"
        @input="setToValue"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <SfButton
        :disabled="disabled"
        class="sf-quantity-selector__button sf-quantity-selector__plus"
        @click.prevent="increment"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </SfButton>
    </div>
    <div
      class="sf-quantity-selector sf-quantity-selector--fraction"
      v-if="isFraction"
    >
      <div class="sf-input">
        <Money
          class="sf-input sf-quantity-selector__input"
          :disabled="disabled"
          :value="qty"
          v-bind="money"
          @input="setToFraction"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SfInput from "../../atoms/SfInput/SfInput.vue";
import SfButton from "../../atoms/SfButton/SfButton.vue";
import { Money } from "v-money";

export default {
  name: "SfQuantitySelector",
  components: {
    SfInput,
    SfButton,
    Money,
  },
  inheritAttrs: false,
  model: {
    prop: "qty",
  },
  data() {
    return {
      money: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " Kg",
        precision: 2,
        masked: false,
      },
    };
  },
  props: {
    /** Quantity */
    qty: {
      type: [Number, String],
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: -1,
    },
    isFraction: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    qty(val) {
      if (val < this.min || isNaN(val)) {
        this.$emit("input", this.min);
      }
    },
  },
  methods: {
    increment() {
      let value = parseInt(this.qty, 10) + 1;
      if (this.max !== -1 && value > this.max) {
        this.$emit("input", this.max);
        this.$emit("hit-max-stock", true);
      } else {
        this.$emit("input", value);
      }
    },
    decrement() {
      let value = parseInt(this.qty, 10) - 1;
      if (value >= this.min) this.$emit("input", value);
      else this.$emit("input", this.min);
    },
    setToValue(value) {
      value = value ? parseInt(value, 10) : 0;
      if (this.max !== -1 && value > this.max) {
        this.$emit("input", this.max);
        this.$emit("hit-max-stock", true);
      } else if (value >= this.min) {
        this.$emit("input", value);
      } else this.$emit("input", this.min);
    },
    setToFraction(value) {
      if (this.qty === value) return;
      if (this.max !== -1 && value > this.max) {
        this.$emit("input", this.max);
        this.$emit("hit-max-stock", true);
      } else if (value >= this.min) {
        this.$emit("input", value);
      } else this.$emit("input", this.min);
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/components/atoms/SfQuantitySelector.scss" as *;

.sf-quantity-selector--fraction {
  .sf-quantity-selector__input {
    --input-border: 1px solid var(--c-primary);
    --input-border-width: 1px;
    --input-color: var(--c-primary);
    --input-font-weight: 600;
    line-height: 1.75rem;
    height: 1.75rem;
    border-radius: 0.25rem;
  }
}
</style>
