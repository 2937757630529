import { MutationTree } from "vuex";
import CheckoutState from "../types/CheckoutState";

const mutations: MutationTree<CheckoutState> = {
  setDeliveryMode(state, payload) {
    state.deliveryMode = payload;
    state.dineInDetails = null;
  },
  setShippingSlotId(state, payload) {
    state.shippingSlotId = payload;
  },
  setDineInSpaceId(state, payload) {
    state.deliveryMode = "dine";
    state.dineInDetails = state.dineInDetails || { spaceId: null, value: null };
    state.dineInDetails.spaceId = payload;
  },
  setDineInValue(state, payload) {
    state.deliveryMode = "dine";
    state.dineInDetails = state.dineInDetails || { spaceId: null, value: null };
    state.dineInDetails.value = payload;
  },
  setDineInDetails(state, payload) {
    state.deliveryMode == "dine";
    state.dineInDetails = payload;
  },
  setPersonalDetails(state, payload) {
    state.personalDetails = payload;
  },
  setShippingDetails(state, payload) {
    state.shippingDetails = payload;
  },
  setOrderId(state, payload) {
    state.orderId = payload;
  },
  setOrderPaymentType(state, payload) {
    state.orderPaymentType = payload;
  },
  selectAddressId(state, payload) {
    state.selectedAddressId = payload;
  },
  setUseSavedAddress(state, payload) {
    state.useSavedAddress = payload;
  },
  onStart(state) {
    state.personalDetails = null;
    state.shippingDetails = null;
    state.shippingSlotId = "";
    state.order = null;
    state.orderId = "";
    state.orderPaymentType = "";
    state.isOrderCompleted = false;
  },
};

export default mutations;
