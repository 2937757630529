import { createInstance } from "localforage";
import { Logger } from "@lib/utility/logger";
import cloneDeep from "lodash-es/cloneDeep";

class LocalForageCacheDriver {
  private _dbName: string;
  private _lastError: any;
  private _localForageCollection: any;

  public constructor(collection) {
    const dbName = collection._config.name;
    this._dbName = dbName;
    this._localForageCollection = collection;
    this._lastError = null;
  }

  public getLastError() {
    return this._lastError;
  }

  public getDbName() {
    return this._dbName;
  }

  public clearAllData(callback?) {
    return this._localForageCollection.clear(callback);
  }

  // Increment the database version number and recreate the context
  public recreateDb() {
    if (this._localForageCollection._config) {
      const existingConfig = cloneDeep(this._localForageCollection._config);
      if (existingConfig.storeName) {
        const newVersion = this._localForageCollection._dbInfo
          ? this._localForageCollection._dbInfo.version + 1
          : 0;
        if (newVersion > 0) {
          this._localForageCollection = createInstance({
            ...existingConfig,
            version: newVersion,
          });
        } else {
          this._localForageCollection = createInstance(existingConfig);
        }
        Logger.log("DB recreated with", existingConfig, newVersion)();
      }
    }
  }

  public getItem(key, callback?) {
    const promise = this._localForageCollection.ready().then(() =>
      this._localForageCollection
        .getItem(key)
        .then((result) => {
          const clonedResult = cloneDeep(result);
          if (callback) {
            callback(null, clonedResult);
          }
          return clonedResult;
        })
        .catch((err) => {
          this._lastError = err;
          if (callback) callback(err, null);
          Logger.error(err)();
        })
    );
    return promise;
  }

  // Iterate over all items in the store.
  public iterate(iterator, callback?) {
    const promise = this._localForageCollection
      .ready()
      .then(() =>
        this._localForageCollection.iterate(
          (value, key, iterationNumber) => {
            if (iterator) {
              iterator(value, key, iterationNumber);
            }
          },
          (err, result) => {
            if (callback) callback(err, result);
          }
        )
      )
      .catch((err) => {
        this._lastError = err;
        Logger.error(err)();
        if (callback) callback(err, null);
      });
    return promise;
  }

  public key(n, callback?) {
    return this._localForageCollection.key(n, callback);
  }

  public keys(callback?) {
    return this._localForageCollection.keys(callback);
  }

  public length(callback?) {
    return this._localForageCollection.length(callback);
  }

  public removeItem(key, callback?) {
    return this._localForageCollection.removeItem(key, callback);
  }

  public setItem(key, value, callback?) {
    const copiedValue = cloneDeep(value);
    const promise = this._localForageCollection.ready().then(() =>
      this._localForageCollection
        .setItem(key, copiedValue)
        .then((result) => {
          if (callback) {
            callback(null, result);
          }
        })
        .catch((err) => {
          this._lastError = err;
          throw err;
        })
    );
    return promise;
  }
}

// The actual localForage object that we expose as a module. It's extended by pulling in one of our other libraries.
export default LocalForageCacheDriver;
