import { GetterTree } from "vuex";
import RootState from "@lib/types/RootState";

const getters: GetterTree<RootState, RootState> = {
  getCurrentStoreView: (state) => state.storeView,
  store: (state) => state.store,
  primaryColor: (state) => {
    let color = "#2874f0";
    if (
      state.store &&
      state.store.themeConfig &&
      state.store.themeConfig.primaryColor
    ) {
      color = state.store.themeConfig.primaryColor;
    }
    return color;
  },
  storeLogo: (state) => {
    if (!state.store) return null;
    if (state.store.logo) {
      return state.store.logo;
    } else if (
      state.store.pwa &&
      state.store.pwa.icons &&
      state.store.pwa.icons.logo192Png
    ) {
      return state.store.pwa.icons.logo192Png;
    }
    return null;
  },
  paymentsEnabled: (state) =>
    state.store &&
    state.store.payment &&
    state.store.payment.providers &&
    state.store.payment.providers.length,
  storeId: (state) => state.storeId,
  isInitialized: (state) => state.libInitialized && state.store,
  personalDetailsConfig: (state) => {
    if (
      !state.store ||
      !state.store.orderConfig ||
      !state.store.orderConfig.detailsConfig ||
      !state.store.orderConfig.detailsConfig.personal
    ) {
      return {
        email: "required",
        phone: "required",
        fullName: "required",
      };
    }
    return state.store.orderConfig.detailsConfig.personal;
  },
  defaultMobileProductsView: (state) => {
    if (
      state.store &&
      state.store.catalog &&
      state.store.catalog.defaultView &&
      state.store.catalog.defaultView.mobile
    ) {
      return state.store.catalog.defaultView.mobile;
    } else {
      return "list";
    }
  },
  defaultDesktopProductsView: (state) => {
    if (
      state.store &&
      state.store.catalog &&
      state.store.catalog.defaultView &&
      state.store.catalog.defaultView.mobile
    ) {
      return state.store.catalog.defaultView.desktop;
    } else {
      return "grid";
    }
  },
};

export default getters;
