import i18n from "@lib/i18n";
import CartItem from "@lib/modules/cart/types/CartItem";

const calculateTotals = (
  cartItems: CartItem[],
  globalTaxPercentage = 0,
  isDelivery,
  deliveryConfig,
  packingConfig
) => {
  let subtotal = 0;
  let total = 0;
  let totalTax = 0;
  for (const item of cartItems) {
    const price_excl_tax =
      item.qty * item.finalPrice || item.discountedPrice || item.basePrice;
    const tax = price_excl_tax * (globalTaxPercentage / 100);
    const price_incl_tax = price_excl_tax + tax;
    subtotal += price_excl_tax;
    totalTax += tax;
    total += price_incl_tax;
  }

  const totalsArray: any[] = [
    {
      code: "subtotal",
      title: i18n.t("Subtotal"),
      value: subtotal,
      isPercentage: false,
      percentAmount: 0,
    },
  ];

  const isDeliveryChargeExists =
    isDelivery && deliveryConfig && deliveryConfig.amount;

  if (isDeliveryChargeExists) {
    const delivery: any = {
      code: "delivery",
      title: i18n.t("Shipping and Handling"),
      value: deliveryConfig.amount,
      isPercentage: false,
      percentAmount: 0,
      isFree: false,
    };
    if (
      deliveryConfig.disableWhenTotalAbove !== 0 &&
      subtotal >= deliveryConfig.disableWhenTotalAbove
    ) {
      delivery.isFree = true;
    } else {
      total += deliveryConfig.amount;
    }
    totalsArray.push(delivery);
  }

  if (packingConfig) {
    let packingCharge: any = null;
    if (packingConfig.type && packingConfig.type === "fixed") {
      packingCharge = {
        code: "packing",
        title: i18n.t("Packing Charges"),
        value: packingConfig.amount,
        isPercentage: false,
        percentAmount: 0,
        isFree: false,
      };
    } else if (packingConfig.type === "percentage") {
      let amount = (packingConfig.amountPercentage * subtotal) / 100;
      amount = Math.round(amount * 100) / 100;
      if (packingConfig.minAmount && amount < packingConfig.minAmount) {
        amount = packingConfig.minAmount;
      } else if (packingConfig.maxAmount && amount > packingConfig.maxAmount) {
        amount = packingConfig.maxAmount;
      }

      if (packingConfig.removeFractionalPart) {
        amount = Math.round(amount);
      }
      packingCharge = {
        code: "packing",
        title: i18n.t("Packing Charges"),
        value: amount,
        isPercentage: false,
        percentAmount: 0,
        isFree: false,
      };
    }
    if (packingCharge) {
      total += packingCharge.value;
      totalsArray.push(packingCharge);
    }
  }

  if (totalTax > 0) {
    totalsArray.push({
      code: "tax",
      title: i18n.t("Tax"),
      value: totalTax,
      isPercentage: true,
      percentAmount: globalTaxPercentage,
    });
  }

  totalsArray.push({
    code: "grand_total",
    title: i18n.t("Grand total"),
    value: total,
    isPercentage: false,
    percentAmount: 0,
  });

  return totalsArray;
};

export default calculateTotals;
