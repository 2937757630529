<template>
  <div class="product-grid-item">
    <component
      :is="linkComponentTag"
      v-focus
      :href="linkComponentTag === 'a' ? link : undefined"
      :to="link && linkComponentTag !== 'a' ? link : undefined"
      class="product-grid-item__link no-underline"
      v-ripple
    >
      <div class="product-grid-item__image-wrapper">
        <SfImage
          class="product-grid-item__image"
          :src="product.image"
          :alt="product.title"
          :width="imageWidth"
          :height="imageHeight"
        />
      </div>
      <div class="product-grid-item__info">
        <h3 class="product-grid-item__title">
          {{ product.title }}
        </h3>
        <p v-if="product.variantName" class="product-grid-item__variant">
          {{ product.variantName }}
        </p>
      </div>
    </component>
    <div class="product-grid-item__actions">
      <SfPrice
        v-if="product.basePrice != null"
        class="product-grid-item__price"
        :regular="product.basePrice | price"
        :special="product.discountedPrice | price"
      />
      <div
        v-if="isCartEnabled && !isOutOfStock"
        class="product-grid-item__add-to-cart-wrapper"
      >
        <SfAddToCart
          :qty="product.qty"
          :product="product"
          :max="showStockErrors ? product.stock : 10000"
          class="product-grid-item__add-to-cart"
        />
      </div>
      <div v-if="isCartEnabled && isOutOfStock">
        <div class="product-grid-item__error">Out of stock</div>
      </div>
    </div>
  </div>
</template>
<script>
import { focus } from "@lib/utility/directives/focus-directive.js";
import { colorsValues as SF_COLORS } from "@lib/variables/colors";
import SfIcon from "../atoms/SfIcon/SfIcon.vue";
import SfPrice from "../atoms/SfPrice/SfPrice.vue";
import SfRating from "../atoms/SfRating/SfRating.vue";
import SfImage from "../atoms/SfImage/SfImage.vue";
import SfCircleIcon from "../atoms/SfCircleIcon/SfCircleIcon.vue";
import SfBadge from "../atoms/SfBadge/SfBadge.vue";
import SfAddToCart from "../molecules/SfAddToCart/SfAddToCart.vue";

export default {
  name: "ProductGridItem",
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfAddToCart,
  },
  directives: { focus },
  props: {
    product: {
      type: Object,
      required: true,
    },
    imageWidth: {
      type: [String, Number],
      default: 200,
    },
    imageHeight: {
      type: [String, Number],
      default: 200,
    },
    link: {
      type: [String, Object],
      default: "",
    },
    linkTag: {
      type: String,
      default: undefined,
    },
    isCartEnabled: {
      type: Boolean,
      default: true,
    },
    showStockErrors: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    linkComponentTag() {
      if (this.linkTag) {
        return this.linkTag;
      }
      if (this.link) {
        return typeof this.link === "object" || this.$router
          ? "router-link"
          : "a";
      }
      return "div";
    },
    isOutOfStock() {
      return this.showStockErrors && this.product.stock <= 0;
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/helpers" as *;
.product-grid-item {
  box-sizing: border-box;
  position: relative;
  z-index: var(--product-card-z-index);
  max-width: var(--product-card-max-width, 100%);
  height: var(--product-card-height);
  padding: var(--product-card-padding, 0.625rem);
  flex: 1 1 100%;
  flex-direction: column;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: var(--product-card-transition);
    box-shadow: var(--product-card-box-shadow);
  }
  &:hover {
    --product-card-image-opacity: 1;
    --product-card-image-even-opacity: 1;
    --product-card-wishlist-icon-opacity: 1;
    --product-card-add-button-opacity: 1;
    --product-card-z-index: 0;
    --product-card-box-shadow: 0px 4px 35px rgba(168, 172, 176, 0.19);
  }
  &:active {
    --product-card-box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
  }

  &__info {
    min-height: 2.4rem;
    margin-bottom: var(--spacer-xs);
  }

  &__title {
    margin: var(--product-card-title-margin, var(--spacer-xs) 0 0 0);
    @include font(
      --product-card-title-font,
      var(--font-normal),
      var(--font-base),
      1.2,
      var(--font-family-primary)
    );
    color: #444848;
  }
  &__variant {
    margin: 0 0 var(--spacer-xs) 0;
    @include font(
      --product-card-variant-font,
      var(--font-light),
      var(--font-sm),
      1.2,
      var(--font-family-secondary)
    );
  }

  &__error {
    margin-bottom: var(--spacer-xs);
    color: red;
    display: inline-block;
    @include font(
      --collected-product-title-font,
      var(--font-normal),
      var(--font-sm),
      1,
      var(--font-family-secondary)
    );
  }
  &__image-wrapper {
    position: relative;
    display: flex;
  }
  &__image,
  &__image-placeholder,
  &__picture {
    transition: var(--product-card-image-transition, opacity 150ms ease-in-out);
    opacity: var(--product-card-image-opacity);
    &:nth-child(even) {
      position: absolute;
      top: 0;
      left: 0;
      opacity: var(--product-card-image-even-opacity, 0);
    }
    border-radius: 0.375rem;
  }
  &__badge {
    position: absolute;
    top: var(--product-card-badge-top, var(--spacer-xs));
    left: var(--product-card-badge-left, 0);
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__price {
    margin: var(--product-card-margin, var(--spacer-2xs) 0);
    flex-basis: 2rem;
    flex-grow: 1;
    max-width: 100%;

    .sf-price__value {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__add-to-cart-wrapper {
    display: flex;
    flex-basis: 2rem;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-end;
    margin-left: 0.125rem;
  }

  &__reviews {
    display: flex;
    align-items: center;
  }
  &__rating {
    display: flex;
  }
  @include for-desktop {
    --product-card-max-width: 15.5rem;
    --product-card-padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-base)
      var(--spacer-sm);
    --product-card-title-margin: 0.75rem 0 0 0;
    --product-card-transition: box-shadow 150ms ease-in-out;
    --product-card-wishlist-icon-top: var(--spacer-lg);
    --product-card-wishlist-icon-right: var(--spacer-lg);
    --product-card-wishlist-icon-opacity: 0;
    --product-card-add-button-display: flex;
  }
}
</style>
