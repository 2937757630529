import { GetterTree } from "vuex";
import sumBy from "lodash-es/sumBy";
import CartState from "../types/CartState";
import RootState from "@lib/types/RootState";
import AppliedCoupon from "../types/AppliedCoupon";
import { calculateTotals } from "@lib/modules/cart/helpers";
import config from "@config/config";

const getters: GetterTree<CartState, RootState> = {
  userId: (state) => state.userId,
  storeId: (state) => state.storeId,
  getCartToken: (state) => state.cartServerToken,
  getLastSyncDate: (state) => state.cartServerLastSyncDate,
  getLastTotalsSyncDate: (state) => state.cartServerLastTotalsSyncDate,
  getShippingMethod: (state) => state.shipping,
  getPaymentMethod: (state) => state.payment,
  getLastCartHash: (state) => state.cartItemsHash,
  isCartHashChanged: (state, getters) =>
    getters.getCurrentCartHash !== state.cartItemsHash,
  isSyncRequired: (state, getters) =>
    getters.isCartHashEmptyOrChanged || !state.cartServerLastSyncDate,
  isTotalsSyncRequired: (state, getters) =>
    getters.isCartHashEmptyOrChanged || !state.cartServerLastTotalsSyncDate,
  isCartHashEmptyOrChanged: (state, getters) =>
    !state.cartItemsHash || getters.isCartHashChanged,
  getCartItems: (state) => state.cartItems,
  getCartQtyMap: (state) =>
    state.cartItems.reduce((map, item) => {
      map[item.id] = item.qty;
      return map;
    }, {}),
  getServerItems: (state) => state.serverItems,
  isCartConnected: (state) => !!state.cartServerToken,
  getFirstShippingMethod: (state) =>
    state.shipping instanceof Array ? state.shipping[0] : state.shipping,
  getFirstPaymentMethod: (state) =>
    state.payment instanceof Array ? state.payment[0] : state.payment,
  getTotals: ({ cartItems }, getters, rootState) => {
    const storeConfig = rootState.store;
    return calculateTotals(
      cartItems,
      storeConfig.pricingConfig.globalTaxPercentage
    );
  },
  getItemsTotalQuantity: ({ cartItems }) =>
    sumBy(cartItems, (p) => p.qty).toFixed(2),
  getNumberOfItems: ({ cartItems }) => cartItems.length,
  getCoupon: ({ platformTotals }): AppliedCoupon | false =>
    !(platformTotals && platformTotals.hasOwnProperty("coupon_code"))
      ? false
      : {
          code: platformTotals.coupon_code,
          discount: platformTotals.discount_amount,
        },
  isVirtualCart: ({ cartItems }) =>
    cartItems.length
      ? cartItems.every(
          (itm) => itm.type_id === "downloadable" || itm.type_id === "virtual"
        )
      : false,
  canUpdateMethods: (state, getters) =>
    getters.isCartSyncEnabled && getters.isCartConnected,
  canSyncTotals: (state, getters) =>
    getters.isTotalsSyncEnabled && getters.isCartConnected,
  isCartEmpty: (state) => state.cartItems.length === 0,
  bypassCounter: (state) => state.connectBypassCount,
  getShippingMethodCode: (state) =>
    state.shipping && state.shipping.method_code,
  getPaymentMethodCode: (state) => state.payment && state.payment.code,
  getIsAdding: (state) => state.isAddingToCart,
  getIsMicroCartOpen: (state) => state.isMicrocartOpen,
  isCartValid: (state, getters, rootState) => {
    if (rootState.store.orderConfig.allowOrderOnNoStock) {
      return true;
    }
    for (const cartItem of state.cartItems) {
      if (cartItem.qty > cartItem.stock) {
        return false;
      }
    }
    return true;
  },
};

export default getters;
