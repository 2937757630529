import { GetterTree } from "vuex";
import RootState from "@lib/types/RootState";
import UserState from "../types/UserState";

const getters: GetterTree<UserState, RootState> = {
  isLoggedIn(state) {
    return state.current !== null;
  },
  isLocalDataLoaded: (state) => state.local_data_loaded,
  getUserToken(state) {
    return state.userToken;
  },
  getOrdersHistory(state) {
    return state.orders_history || [];
  },
  ordersLoading: (s) => s.orders_loading,
  ordersError: (s) => s.orders_error,
  getUserEmail(state, getters) {
    return getters.isLoggedIn ? state.current.email : null;
  },
  userId(state, getters) {
    return getters.isLoggedIn ? state.current.id : null;
  },
  phoneNumber(state, getters) {
    return getters.isLoggedIn ? state.current.phoneNumber : null;
  },
  email(state, getters) {
    return getters.isLoggedIn ? state.current.email : null;
  },
  displayName(state, getters) {
    return getters.isLoggedIn ? state.current.name : null;
  },
  addresses(state) {
    return state.addresses || [];
  },
  countryCode(state) {
    return state.countryCode || null;
  },
  canUsePhoneOtp(state) {
    return state.countryCode && state.countryCode === "IN";

    // Now if a user tries logging in using a number, send the OTP and ask him to migrate to email and password
    // When migration
  },
};

export default getters;
