import axios from "axios";
import config from "@config/config";

const createAppointment = async (
  sfId: string,
  userId: string,
  appointment: any
): Promise<any> => {
  const appointmentResource = config.serverlessUrl + "appointment/create";
  const response = await axios.post(appointmentResource, {
    userId,
    sfId,
    appointment,
  });
  return response.data;
};

export default createAppointment;
