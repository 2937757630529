import { ActionTree } from "vuex";
import RootState from "@lib/types/RootState";
import CartState from "@lib/modules/cart/types/CartState";
import itemActions from "./itemActions";
import syncActions from "./syncActions";
import connectActions from "./connectActions";

const actions: ActionTree<CartState, RootState> = {
  init({ commit, dispatch, rootState }) {
    commit("setStoreId", rootState.storeId);
    dispatch("load");
  },
  ...itemActions,
  ...syncActions,
  ...connectActions,
};

export default actions;
