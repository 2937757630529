import { MutationTree } from "vuex";
import * as types from "./mutation-types";
import RootState from "@lib/types/RootState";
import StoreConfig from "@lib/types/StoreConfig";

const mutations: MutationTree<RootState> = {
  [types.USER_TOKEN_INVALIDATE_LOCK_CHANGED](state, payload) {
    state.userTokenInvalidateLock = payload;
  },
  setLibInitialized(state, payload) {
    state.libInitialized = payload;
  },
  setStoreId(state, storeId) {
    state.storeId = storeId;
  },
  setStoreFetchWaiting(state, payload: boolean) {
    state.storeFetchWaiting = payload;
  },
  setStore(state, payload: StoreConfig) {
    state.store = payload;
    state.storeFetchWaiting = false;
  },
  setStoreNotSpecified(state, payload: boolean) {
    state.storeNotSpecified = payload;
  },
  setStoreNotFound(state, payload: boolean) {
    state.storeNotFound = payload;
    state.storeFetchWaiting = false;
  },
  setStoreFetchError(state, payload: string) {
    state.storeFetchError = payload;
    state.storeFetchWaiting = false;
  },
  setCountryCode(state, payload) {
    state.countryCode = payload;
  },
};

export default mutations;
