import axios from "axios";
import config from "@config/config";

const getOrders = async (storeId) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const orderResource = config.serverUrl + "api/user/orders";

  const response = await axios.get(orderResource);
  return response.data;
};

const getAppointments = async (storeId) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const appointmentsResource = config.serverUrl + "api/user/appointments";

  const response = await axios.get(appointmentsResource);
  return response.data;
};

const getAddresses = async (storeId) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const addressesResource = config.serverUrl + "api/user/addresses";

  const response = await axios.get(addressesResource);
  return response.data;
};

const updateAddress = async (storeId, userId, address) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  let addressesResource = config.serverUrl + "api/user/addresses";

  if (address.id) {
    addressesResource += "/" + address.id;
  }

  const response = await axios.post(addressesResource, address);
  return response.data;
};

const deleteAddress = async (storeId, userId, addressId) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  let addressesResource = config.serverUrl + "api/user/addresses/" + addressId;

  const response = await axios.delete(addressesResource);
  return response.data;
};

const loginUsingPhone = async (phone, password) => {
  const phoneLoginResource = config.serverUrl + "api/auth/phone/login";
  try {
    const response = await axios.post(phoneLoginResource, {
      phone,
      password,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const sendOtpForPhoneSignup = async (name, phoneNumber) => {
  const phoneSignupResource =
    config.serverUrl + "api/auth/phone/sendOtpForSignup";
  try {
    const response = await axios.post(phoneSignupResource, {
      name,
      phoneNumber,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const sendOtpForVerification = async (phoneNumber) => {
  const phoneSignupResource =
    config.serverUrl + "api/auth/phone/sendOtpForVerification";
  try {
    const response = await axios.post(phoneSignupResource, {
      phoneNumber,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const signupUsingPhone = async (name, phoneNumber, password, otp) => {
  const phoneSignupResource = config.serverUrl + "api/auth/phone/signup";
  try {
    const response = await axios.post(phoneSignupResource, {
      name,
      phoneNumber,
      password,
      otp,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.log(error.response.data);
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const loginUsingEmail = async (email, password) => {
  const emailLoginResource = config.serverUrl + "api/auth/email/login";
  try {
    const response = await axios.post(emailLoginResource, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const signupUsingEmail = async (name, email, password) => {
  const emailSignupResource = config.serverUrl + "api/auth/email/signup";
  try {
    const response = await axios.post(emailSignupResource, {
      name,
      email,
      password,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const sendPasswordResetCodeToPhone = async (phoneNumber) => {
  const otpResource =
    config.serverUrl + "api/auth/phone/sendCodeForPasswordReset";
  let response = null;
  try {
    response = await axios.post(otpResource, {
      phoneNumber: phoneNumber,
    });
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errorMessage
    ) {
      throw new Error(error.response.data.errorMessage);
    } else {
      throw new Error("Error occurred while sending Password reset code");
    }
  }
  if (!response.data || response.data.status !== "success") {
    throw new Error("Error occurred while sending Password reset code");
  }
};

const sendPasswordResetCodeToEmail = async (email) => {
  const otpResource =
    config.serverUrl + "api/auth/email/sendCodeForPasswordReset";
  let response = null;
  try {
    response = await axios.post(otpResource, {
      email: email,
    });
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errorMessage
    ) {
      throw new Error(error.response.data.errorMessage);
    } else {
      throw new Error("Error occurred while sending Password reset code");
    }
  }
  if (!response.data || response.data.status !== "success") {
    throw new Error("Error occurred while sending Password reset code");
  }
};

const verifyPasswordResetCodeForPhone = async (phoneNumber, otp) => {
  console.log({ phoneNumber, otp });
  const resource =
    config.serverUrl + "api/auth/phone/verifyCodeForPasswordReset";
  try {
    const response = await axios.post(resource, {
      phoneNumber,
      otp,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.log(error.response.data);
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const verifyPasswordResetCodeForEmail = async (email, otp) => {
  const resource =
    config.serverUrl + "api/auth/email/verifyCodeForPasswordReset";
  try {
    const response = await axios.post(resource, {
      email,
      otp,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.log(error.response.data);
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const resetPhonePassword = async (phoneNumber, password, otp) => {
  const resource = config.serverUrl + "api/auth/phone/resetPassword";
  try {
    const response = await axios.post(resource, {
      phoneNumber,
      password,
      otp,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.log(error.response.data);
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const resetEmailPassword = async (email, password, otp) => {
  const resource = config.serverUrl + "api/auth/email/resetPassword";
  try {
    const response = await axios.post(resource, {
      email,
      password,
      otp,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.log(error.response.data);
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const updateEmailAddress = async (email) => {
  const resource = config.serverUrl + "api/auth/email/updateEmail";
  try {
    const response = await axios.post(resource, {
      newEmail: email,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const updatePhoneNumber = async (phoneNumber, otp) => {
  const resource = config.serverUrl + "api/auth/phone/updatePhone";
  try {
    const response = await axios.post(resource, {
      newPhoneNumber: phoneNumber,
      otp: otp,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const updateName = async (fullName) => {
  const resource = config.serverUrl + "api/user/updateName";
  try {
    const response = await axios.post(resource, {
      newName: fullName,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw new Error("There was an error connecting to the server.");
  }
};

const sendPhoneLoginOtp = async (userPhoneNumber) => {
  const otpResource = config.serverUrl + "api/auth/phone/sendOtp";
  const response = await axios.post(otpResource, {
    phoneNumber: userPhoneNumber,
  });
  if (!response.data || response.data.status !== "success") {
    throw new Error("Error occurred while sending OTP");
  }
};

const verifyPhoneOtp = async (phoneNumber, otp) => {
  const otpResource = config.serverUrl + "api/auth/phone/verifyOtp";
  const response = await axios.post(otpResource, {
    phoneNumber: phoneNumber,
    receivedOtp: otp,
  });
  return response.data.authToken;
};

const sendEmailLoginOtp = async (userEmail) => {
  const otpResource = config.serverUrl + "api/auth/email/sendOtp";
  const response = await axios.post(otpResource, {
    email: userEmail,
  });
  if (!response.data || response.data.status !== "success") {
    throw new Error("Error occurred while sending OTP");
  }
};

const verifyEmailOtp = async (email, otp) => {
  const otpResource = config.serverUrl + "api/auth/email/verifyOtp";
  const response = await axios.post(otpResource, {
    email: email,
    receivedOtp: otp,
  });
  return response.data.authToken;
};

export default {
  getOrders,
  getAppointments,
  getAddresses,
  updateAddress,
  deleteAddress,
  sendPhoneLoginOtp,
  verifyPhoneOtp,
  sendEmailLoginOtp,
  verifyEmailOtp,
  sendOtpForPhoneSignup,
  sendOtpForVerification,
  signupUsingPhone,
  loginUsingPhone,
  loginUsingEmail,
  signupUsingEmail,
  sendPasswordResetCodeToPhone,
  sendPasswordResetCodeToEmail,
  verifyPasswordResetCodeForPhone,
  verifyPasswordResetCodeForEmail,
  resetPhonePassword,
  resetEmailPassword,
  updateEmailAddress,
  updatePhoneNumber,
  updateName,
};
