import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faThumbsUp,
  faEnvelope,
  faClone,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faPlus,
  faMinus,
  faCircle,
  faPlusSquare,
  faTh,
  faPencilAlt,
  faHome,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VueConstructor } from "vue/types/umd";

library.add(
  faPlusSquare,
  faCheck,
  faPlus,
  faMinus,
  faWhatsapp,
  faTh,
  faThumbsUp,
  faPencilAlt,
  faEnvelope,
  faClone,
  faCaretRight,
  faHome,
  faCircle
);

const registerIcons = (Vue: VueConstructor<Vue>) => {
  Vue.component("font-awesome-icon", FontAwesomeIcon);
};

export { registerIcons };
