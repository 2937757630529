const messaging = require("./firebase").messaging;
import config from "@config/config";

const notificationListeners = [];

if (messaging) {
  messaging.onMessage((payload) => {
    let notification = payload.notification;
    let data = payload.data;
    for (const listener of notificationListeners) {
      listener(payload);
    }
    navigator.serviceWorker.getRegistration().then((registration) => {
      const notificationOptions = {
        body: notification.body,
        icon:
          data.icon ||
          "https://firebasestorage.googleapis.com/v0/b/zo-front.appspot.com/o/sf%2Fdefaults%2Flogo192.png?alt=media",
        badge:
          data.icon ||
          "https://firebasestorage.googleapis.com/v0/b/zo-front.appspot.com/o/sf%2Fdefaults%2Flogo192.png?alt=media",
        data: data,
      };
      registration.showNotification(notification.title, notificationOptions);
    });
  });
}

const getFcmToken = () => {
  if (isDenied()) {
    return null;
  }
  return new Promise((resolve, reject) => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (!registration) {
        reject("No service worker registration found");
        return;
      }
      if (!messaging) return;
      messaging
        .getToken({
          vapidKey: config.fcmVapidKey,
          serviceWorkerRegistration: registration,
        })
        .then((regToken) => {
          resolve(regToken);
        })
        .catch((e) => reject(e));
    });
  });
};

const isEnabled = () => {
  return Notification.permission === "granted";
};

const isDenied = () => {
  return Notification.permission === "denied";
};

const addMessageListener = (listener) => {
  notificationListeners.push(listener);
};

export { getFcmToken, isEnabled, addMessageListener };
