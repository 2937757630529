<template>
  <div
    class="default-layout"
    :style="{
      '--color-primary': primaryColor,
      '--c-primary': primaryColor,
    }"
  >
    <loader />
    <div id="viewport" v-if="isInitialized">
      <basic-header
        v-if="headerType === 'basic'"
        :store-title="store.title"
        :is-cart-enabled="isCartEnabled"
      />
      <main-header
        v-if="headerType === 'main'"
        :store-title="store.title"
        :is-cart-enabled="isCartEnabled"
      />
      <EnableNotificationsPrompt />
      <SfSidebar
        v-if="isCartEnabled"
        :visible="isMicrocartOpen"
        :title="microcartTitle"
        class="sf-sidebar--right sidebar__microcart"
        @close="$store.commit('ui/setMicrocart')"
      >
        <component
          :is="microcartAsyncComponent"
          v-if="isMicrocartOpen"
          @close="$store.commit('ui/setMicrocart')"
          @reload="reloadComponent()"
        />
      </SfSidebar>
      <sidebar />
      <slot />
      <modal />
      <bottom-navigation v-if="hasBottomNav" />
      <notification />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import MainHeader from "@theme/components/Header/MainHeader.vue";
import BasicHeader from "@theme/components/Header/BasicHeader.vue";
import Overlay from "@theme/components/Overlay.vue";
import Loader from "@theme/components/Loader.vue";
import { SfSidebar } from "@lib/components";
import ALoadingSpinner from "@theme/components/atoms/a-loading-spinner";
import ALoadingError from "@theme/components/atoms/a-loading-error";
import Notification from "@theme/components/organisms/notification";
import HeadMetadata from "@theme/head";
import Modal from "@theme/components/organisms/modal";
import sidebar from "@theme/components/organisms/sidebar";
import BottomNavigation from "@theme/components/organisms/bottomNavigation";
import EnableNotificationsPrompt from "@theme/components/molecules/enable-notifications-prompt";

const OMicrocart = () =>
  import(
    /* webpackChunkName: "microcart" */ "@theme/components/organisms/microcart"
  );

export default {
  name: "home-layout",
  components: {
    Overlay,
    Loader,
    MainHeader,
    BasicHeader,
    SfSidebar,
    Notification,
    Modal,
    sidebar,
    BottomNavigation,
    EnableNotificationsPrompt,
  },
  props: {
    headerType: {
      default: "main",
    },
    hasBottomNav: {
      default: true,
    },
  },
  data() {
    return {
      microcartAsyncComponent: () => ({
        component: OMicrocart(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000,
      }),
    };
  },
  computed: {
    ...mapGetters(["store", "isInitialized"]),
    ...mapGetters({
      numberOfItems: "cart/getNumberOfItems",
    }),
    ...mapState({
      overlayActive: (state) => state.ui.overlay,
      isSearchPanelOpen: (state) => state.ui.searchpanel,
      isSidebarOpen: (state) => state.ui.sidebar,
      isMicrocartOpen: (state) => state.ui.microcart,
      isWishlistOpen: (state) => state.ui.wishlist,
    }),
    ...mapState([
      "storeId",
      "storeFetchWaiting",
      "storeNotFound",
      "storeFetchError",
    ]),
    microcartTitle() {
      if (this.numberOfItems) {
        return `Total Items: ${this.numberOfItems}`;
      }
      return "Cart is Empty";
    },
    primaryColor() {
      let color = "#2874f0";
      if (
        this.store &&
        this.store.themeConfig &&
        this.store.themeConfig.primaryColor
      ) {
        color = this.store.themeConfig.primaryColor;
      }
      return color;
    },
    isCartEnabled() {
      return (
        this.store.orderConfig.orderEnabled ||
        this.store.orderConfig.enquiryEnabled
      );
    },
  },
  watch: {
    storeFetchWaiting: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$store.commit("ui/setLoader", true);
        } else {
          this.$store.commit("ui/setLoader", false);
        }
      },
    },
    storeNotFound: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$router.replace({ name: "error" });
        }
      },
    },
    storeFetchError: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$router.replace({ name: "error" });
        }
      },
    },
    store: {
      immediate: true,
      handler: function (val) {
        if (val) {
          document.title = val.title || "home";
        }
      },
    },
  },
  methods: {
    reloadComponent() {
      this.microcartAsyncComponent = () => ({
        component: OMicrocart(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 3000,
      });
    },
  },
};
</script>

<style lang="scss">
.default-layout {
  min-height: 100vh;
  background: #fafcff;
  --sidebar-z-index: 5;

  .viewport {
    height: 100%;
  }
}
</style>
