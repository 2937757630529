import axios from "axios";
import config from "@config/config";

const getItems = async (storeId) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const orderResource = config.serverUrl + "api/user/cart";

  const response = await axios.get(orderResource);
  return response.data;
};

const updateItem = async (storeId, uId, cartItem) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const orderResource = config.serverUrl + "api/user/cart/" + cartItem.id;

  const response = await axios.post(orderResource, cartItem);
  return response.data;
};

const removeItem = async (storeId, uId, cartItem) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const cartResource = config.serverUrl + "api/user/cart/" + cartItem.id;

  await axios.delete(cartResource);
};

const clearCart = async (storeId, uId) => {
  if (!storeId) {
    throw new Error("Store not specified");
  }

  const cartResource = config.serverUrl + "api/user/cart";

  await axios.delete(cartResource);
};

export default {
  getItems,
  updateItem,
  clearCart,
  removeItem,
};
