import { Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import RootState from "@lib/types/RootState";
import UserState from "../types/UserState";

export const userStore: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    userToken: "",
    refreshToken: "",
    groupToken: "",
    groupId: null,
    current: null,
    current_storecode: "",
    session_started: new Date(),
    orders_history: null,
    orders_loading: true,
    orders_error: null,
    local_data_loaded: false,
    addresses: [],
    countryCode: null,
  },
  getters,
  actions,
  mutations,
};
