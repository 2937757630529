export const SN_USER = "user";
export const USER_NEWSLETTER_SIGNUP = SN_USER + "/NEWSLETTER_SIGNUP";
export const USER_TOKEN_CHANGED = SN_USER + "/TOKEN_CHANGED";
export const USER_INFO_LOADED = SN_USER + "/INFO_LOADED";
export const USER_ORDERS_RESET = SN_USER + "/ORDERS_RESET";
export const USER_ORDERS_HISTORY_LOADED = SN_USER + "/ORDERS_HISTORY_LOADED";
export const USER_ORDERS_ERROR = SN_USER + "/ORDERS_ERROR";
export const USER_START_SESSION = SN_USER + "/START_SESSION";
export const USER_END_SESSION = SN_USER + "/END_SESSION";
export const USER_UPDATE_PREFERENCES = SN_USER + "/UPDATE_PREFERENCES";
export const USER_GROUP_TOKEN_CHANGED = SN_USER + "/GROUP_TOKEN_CHANGED";
export const USER_GROUP_CHANGED = SN_USER + "/GROUP_ID_CHANGED";
export const USER_LOCAL_DATA_LOADED = SN_USER + "/LOCAL_DATA_LOADED";
export const USER_ADDRESSES_LOADED = SN_USER + "/USER_ADDRESSES_LOADED";
export const USER_ADDRESS_UPDATED = SN_USER + "/USER_ADDRESS_UPDATED";
export const USER_ADDRESS_DELETED = SN_USER + "/USER_ADDRESS_DELETED";
