import { updateInstanceId } from "./instance";

import { getFcmToken, addMessageListener } from "../push-messaging.js";

const isEnabled = () => {
  return Notification.permission === "granted";
};

const requestPermission = async (userId) => {
  try {
    const fcmToken = await getFcmToken();
    if (fcmToken) {
      await updateInstanceId(fcmToken, userId);
    }
  } catch (error) {
    console.error(error);
  }
};

const attachUserToInstance = async (userId) => {
  if (!isEnabled()) return;
  try {
    const fcmToken = await getFcmToken();
    if (fcmToken) {
      await updateInstanceId(fcmToken, userId);
    }
  } catch (error) {
    console.error(error);
  }
};

export default {
  isEnabled,
  attachUserToInstance,
  requestPermission,
};
