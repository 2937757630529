<template>
  <button
    type="button"
    class="bg-cl-transparent brdr-none inline-flex"
    @click="openSidebarMenu"
    :aria-label="$t('Open menu')"
    data-testid="menuButton"
  >
    <i class="material-icons">menu</i>
  </button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HamburgerIcon",
  computed: mapState({
    isOpen: (state) => state.ui.sidebar,
  }),
  methods: {
    openSidebarMenu() {
      this.$store.commit("ui/setSidebar", !this.isOpen);
    },
  },
};
</script>
