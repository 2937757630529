import { MutationTree } from "vuex";
import * as types from "./mutation-types";
import UserState from "../types/UserState";
import { Logger } from "@lib/utility/logger";
import Vue from "vue";

const mutations: MutationTree<UserState> = {
  [types.USER_TOKEN_CHANGED](state, payload) {
    state.userToken = payload.userToken;
    if (payload.meta && payload.meta.refreshToken) {
      state.refreshToken = payload.meta.refreshToken; // store the refresh token
      Logger.log("Refresh token is set to" + state.refreshToken, "user")();
    }
  },
  [types.USER_START_SESSION](state) {
    state.session_started = new Date();
  },
  [types.USER_GROUP_TOKEN_CHANGED](state, token) {
    state.groupToken = token;
  },
  [types.USER_GROUP_CHANGED](state, groupId) {
    state.groupId = groupId;
  },
  [types.USER_INFO_LOADED](state, currentUser) {
    state.current = currentUser;
  },
  [types.USER_ORDERS_RESET](state) {
    state.orders_loading = true;
    state.orders_error = null;
    state.orders_history = [];
  },
  [types.USER_ORDERS_HISTORY_LOADED](state, ordersHistory) {
    state.orders_loading = false;
    state.orders_error = null;
    state.orders_history = ordersHistory;
  },
  [types.USER_ORDERS_ERROR](state, message) {
    state.orders_loading = false;
    state.orders_error = message;
    state.orders_history = [];
  },
  [types.USER_END_SESSION](state) {
    state.userToken = "";
    state.current = null;
    state.session_started = null;
  },
  [types.USER_LOCAL_DATA_LOADED](state, readed = false) {
    state.local_data_loaded = readed;
  },
  [types.USER_ADDRESSES_LOADED](state, addresses) {
    state.addresses = addresses;
  },
  [types.USER_ADDRESS_UPDATED](state, address) {
    const addressIndex = state.addresses.findIndex((x) => x.id === address.id);
    if (addressIndex !== -1) {
      Vue.set(state.addresses, addressIndex, address);
    } else {
      state.addresses.push(address);
    }
  },
  [types.USER_ADDRESS_DELETED](state, addressId) {
    const addressIndex = state.addresses.findIndex((x) => x.id === addressId);
    if (addressIndex !== -1) {
      state.addresses.splice(addressIndex, 1);
    }
  },
  setCountryCode(state, code) {
    state.countryCode = code;
  },
};

export default mutations;
