import i18n from "@lib/i18n";
import CartItem from "@lib/modules/cart/types/CartItem";

const calculateTotals = (cartItems: CartItem[], globalTaxPercentage = 0) => {
  let subtotal = 0;
  let total = 0;
  let totalTax = 0;
  for (const item of cartItems) {
    const price_excl_tax = item.qty * (item.discountedPrice || item.basePrice);
    const tax = price_excl_tax * (globalTaxPercentage / 100);
    const price_incl_tax = price_excl_tax + tax;
    subtotal += price_excl_tax;
    totalTax += tax;
    total += price_incl_tax;
  }

  const totalsArray = [
    {
      code: "subtotal",
      title: i18n.t("Subtotal"),
      value: subtotal,
      isPercentage: false,
      percentAmount: 0,
    },
  ];

  if (totalTax > 0) {
    totalsArray.push({
      code: "tax",
      title: i18n.t("Tax"),
      value: totalTax,
      isPercentage: true,
      percentAmount: globalTaxPercentage,
    });
  }

  totalsArray.push({
    code: "grand_total",
    title: i18n.t("Grand total"),
    value: total,
    isPercentage: false,
    percentAmount: 0,
  });

  return totalsArray;
};

export default calculateTotals;
