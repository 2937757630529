<template>
  <div class="sf-image__wrapper">
    <div
      v-if="source"
      class="sf-image"
      :class="{ 'sf-image--has-size': wrapper }"
      :style="wrapper"
      v-on="$listeners"
      @mouseover="overlay = true"
      @mouseleave="overlay = false"
    >
      <template v-if="typeof source === 'string'">
        <img
          v-if="show || forceShow"
          ref="image"
          :src="source"
          :alt="alt"
          :width="width"
          :height="height"
        />
      </template>
      <template v-else>
        <picture>
          <source
            :srcset="source.desktop.url"
            :media="`(min-width: ${pictureBreakpoint}px)`"
          />
          <source
            :srcset="source.mobile.url"
            :media="`(max-width: ${pictureBreakpoint}px)`"
          />
          <img
            v-if="show"
            ref="image"
            :src="source"
            :alt="alt"
            :width="width"
            :height="height"
          />
        </picture>
      </template>
      <transition name="fade">
        <div v-if="showOverlay" class="sf-image__overlay">
          <slot />
        </div>
      </transition>
    </div>
    <template v-else>
      <div class="image-placeholder">
        <div class="image-placeholder__text" v-text="placeholderText"></div>
      </div>
    </template>
  </div>
</template>
<script>
import lozad from "lozad";
export default {
  name: "SfImage",
  props: {
    src: {
      type: [String, Object],
      default: () => ({ mobile: { url: "" }, desktop: { url: "" } }),
    },
    alt: {
      type: String,
      default: "",
    },
    width: {
      type: [String, Number],
      default: undefined,
    },
    height: {
      type: [String, Number],
      default: undefined,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    pictureBreakpoint: {
      type: Number,
      default: 1024,
    },
    rootMargin: {
      type: String,
      default: "0px 0px 0px 0px",
    },
    threshold: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      overlay: false,
      forceShow: true,
    };
  },
  computed: {
    source() {
      let src = this.src || null;
      if (src && typeof src === "object") {
        if (!src.desktop || !src.mobile) {
          const object = src.desktop || src.mobile || { url: "" };
          src = object.url;
        }
      }
      return src;
    },
    showOverlay() {
      return this.$slots.default && this.overlay;
    },
    wrapper() {
      return (
        this.width &&
        this.height &&
        `--_image-width: ${this.width}; --_image-height: ${this.height}`
      );
    },
    placeholderText() {
      if (!this.alt) return "No Image";
      let placeholder = this.alt;
      if (this.width && this.width > 400) {
        if (placeholder.length > 20) {
          placeholder = placeholder.substring(0, 17) + "...";
        }
      } else {
        placeholder = placeholder.split(" ")[0];
      }
      return placeholder;
    },
  },
  watch: {
    lazy: {
      handler(value) {
        this.show = !value;
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.lazy) {
      this.show = true;
      return;
    }
    this.lozad();
  },
  methods: {
    lozad() {
      const vm = this;
      this.$nextTick(() => {
        const observer = lozad(vm.$el, {
          load() {
            vm.show = true;
          },
          rootMargin: this.rootMargin,
          threshold: this.threshold,
        });
        observer.observe();
      });
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/components/atoms/SfImage" as *;
@use "~@lib/styles/helpers/breakpoints" as *;
.sf-image__wrapper {
  display: flex;
  flex: 1;

  img,
  .image-placeholder {
    border-radius: 0.375rem;
  }

  .image-placeholder {
    width: var(--placeholder-width, 100%);
    display: flex;
    background: #eff1f3;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-primary);
    position: relative;

    &:after {
      display: block;
      content: "";
      padding-bottom: 100%;
    }

    &__text {
      position: absolute;
      padding: 0 1rem;
      color: #d2d5d9;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: var(--font-sm);
      text-align: center;
      max-width: 100%;
      line-height: 1rem;

      @include for-desktop {
        font-size: var(--font-xl);
        line-height: 1.8rem;
      }
    }
  }
}
</style>
