import { Module } from "vuex";
import { createAppointment } from "../helpers";
import userService from "@lib/services/user";

export const AppointmentStore: Module<any, any> = {
  namespaced: true,
  state: {
    userId: null,
    storeId: null,
    current: null,
    list: [],
  },
  getters: {
    userId: (state) => state.userId,
    storeId: (state) => state.storeId,
    current: (state) => state.current,
    list: (state) => state.list,
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId;
    },
    setStoreId(state, storeId) {
      state.storeId = storeId;
    },
    clearUserAppointments(state) {
      state.list = [];
    },
    setUserAppointments(state, appointments) {
      state.list = appointments;
    },
    setCurrentAppointment(state, appointment) {
      state.current = appointment;
    },
  },
  actions: {
    init({ commit, dispatch, rootState }) {
      commit("setStoreId", rootState.storeId);
    },
    async create({ getters, dispatch, commit }, appointment) {
      commit("setCurrentAppointment", appointment);
      const sfId = getters.storeId;
      const userId = getters.userId;
      const { billId } = await createAppointment(sfId, userId, appointment);
      if (userId) await dispatch("fetchAppointmentsForUser");
      return billId;
    },
    setCurrentAppointment({ commit }, appointment) {
      commit("setCurrentAppointment", appointment);
    },
    async onLogin({ commit, dispatch }, { userId }) {
      commit("setUserId", userId);
      await dispatch("fetchAppointmentsForUser");
    },
    async onLogout({ commit }, payload) {
      commit("setUserId", null);
      commit("clearUserAppointments");
    },
    async fetchAppointmentsForUser({ commit, state }) {
      const appointments = await userService.getAppointments(state.storeId);
      commit("setUserAppointments", appointments);
    },
  },
};
