import i18n from "@lib/i18n";

const productAddedToCart = () => ({
  type: "success",
  message: i18n.t("Added to cart!"),
  action1: { label: i18n.t("OK") },
});

const productQuantityUpdated = () => ({
  type: "success",
  message: i18n.t("Product quantity has been updated!"),
  action1: { label: i18n.t("OK") },
});

const unsafeQuantity = () => ({
  type: "warning",
  message: i18n.t(
    "The system is not sure about the stock quantity (volatile). Added to cart for pre-reservation."
  ),
  action1: { label: i18n.t("OK") },
});

const outOfStock = () => ({
  type: "error",
  message: i18n.t(
    "The product is out of stock and cannot be added to the cart!"
  ),
  action1: { label: i18n.t("OK") },
});

const createNotification = ({ type, message }) => ({
  type,
  message,
  action1: { label: i18n.t("OK") },
});
const createNotifications = ({ type, messages }) =>
  messages.map((message) => createNotification({ type, message }));

export {
  createNotification,
  createNotifications,
  productAddedToCart,
  productQuantityUpdated,
  unsafeQuantity,
  outOfStock,
};
